import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RootStoreProvider } from './contexts/RootStoreProvider';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from "@mui/material";
import { getTheme } from './theme/themeLoader';
import { SnackbarProvider, closeSnackbar } from 'notistack';

import { RootStore } from './stores/RootStore';


import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './auth/msal/authConfig';
import { UserStoreMSAL } from './stores/user/UserStoreMSAL';
import { UserStore } from './stores/user/UserStore';

import { MsalProvider } from '@azure/msal-react';
import MsalInitialization from './contexts/MsalInitialization';
import RouteAuthenticationGuardMsal from './routes/RouteAuthenticationGuardMsal';
import RouteAuthenticationGuard from './routes/RouteAuthenticationGuard';
import LoginForward from './components/LoginForward/LoginForward';

import Login from './components/Login/Login';
import { Close } from '@mui/icons-material';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);
// msalInstance.clearCache();

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});

if (process.env.REACT_APP_NAME === "GFT") {
  require("./styles/style_gft.css");
}
else if (process.env.REACT_APP_NAME === "LBBW") {
  require("./styles/style_lbbw.css");
}
else if (process.env.REACT_APP_NAME === "MMake") {
  require("./styles/style_mmake.css");
}
else {
  require("./styles/style_gft.css");
}


let userStore = undefined
// console.log(process.env.REACT_APP_AUTH)

let isMsal = process.env.REACT_APP_AUTH === "msal"
// console.log(isMsal)
if (isMsal) {
  userStore = new UserStoreMSAL()
}
else {
  userStore = new UserStore()
}

let rootStore = new RootStore()


const root = ReactDOM.createRoot(
  document.getElementById('root')
);



root.render(
  <ThemeProvider theme={getTheme()}>
    <RootStoreProvider rootStore={rootStore}>
      <BrowserRouter>
        <React.StrictMode>
          <SnackbarProvider 
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} 
            maxSnack={3}
            action={
              (id) => (
                <Close sx={{color: 'white', ':hover': { transform: 'scale(1.25)', cursor: 'pointer'}}} onClick={() => closeSnackbar(id)}/>
            )
            }>
            {isMsal === true &&
              <MsalProvider instance={msalInstance}>
                <MsalInitialization userStore={userStore} >
                  <App userStore={userStore} authenticationGuard={< RouteAuthenticationGuardMsal />}
                    loginComponent={<LoginForward />}
                  />
                </MsalInitialization>
              </MsalProvider>}
            {isMsal === false &&
              <App userStore={userStore} authenticationGuard={< RouteAuthenticationGuard />}
                loginComponent={<Login />}
              />
            }
          </SnackbarProvider>
        </React.StrictMode>
      </BrowserRouter>
    </RootStoreProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
