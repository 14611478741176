
import { action, makeObservable, observable } from 'mobx';
import { BaseStraemingChatStore, HandleMesssageResult, IHandleSendMessage } from '../../../stores/BaseStreamingChatStore';
import { RootStore } from '../../../stores/RootStore';

import { chatService } from '../../../services/ChatService';
import { Constants } from '../../../contants/Constants';
import { IFeaturePluginStore } from '../../../plugin/types';
import { ReceiveParameters } from '../../../utils/serverside/ServerConnectionHandler';

import i18n from '../../../i18n';
import { v4 as uuidv4 } from 'uuid';
import { ContentStatus, IContentFilter, IContentFilterResult } from '../../../AIShield/AIShieldTypes';


export class SummarizationStore implements IFeaturePluginStore {

    _initialMessage: string | undefined = undefined

    _openClearDialog: boolean = false

    _serviceName: string = "summarization"

    _requestInProgress: boolean = false

    _rootStore: RootStore | undefined = undefined

    constructor(rootStore: RootStore) {

        this._serviceName = Constants.Services.Chat

        this._rootStore = rootStore

    }

    get requestInProgress(): boolean {
        return this._requestInProgress
    }

    setRequestInProgress(requestInProgress: boolean) {
        this._requestInProgress = requestInProgress
    }

    setOpenClearDialog(openClearDialog: boolean) {
        this._openClearDialog = openClearDialog
    }

    get openClearDialog() {
        return this._openClearDialog
    }

    quickActionClearChatHistory() {
        this.setOpenClearDialog(true);
    };

    handleClearChatYes() {
        this.setOpenClearDialog(false);
        this.onNewChat()
    };

    handleClearChatNo() {
        this.setOpenClearDialog(false);
    };

    get initialMessage(): string | undefined {
        return this._initialMessage
    }

    // setInitialMessage(initialMessage: string | undefined, reset: boolean = false) {
    //     this._initialMessage = initialMessage
    //     if (reset === true) {
    //         this.resetPromptSuggestions()
    //     }
    // }



    async initialize() {
        console.log('Initializing Chat Store')

        let userId = this._rootStore?.userStore?.currentUser?.guid
        if (userId !== undefined) {


            this._rootStore?.fireUpdateUI()
        }
    }

    onNewChat() {
        // super.clearChat()
        // this.resetPromptSuggestions()
        // this.setInitialMessage(undefined)
        // this.modelSelectionStore?.setModelSelectionVisible(true)
        // this.resetSelectedConverstation()
    }

    clear() {
        console.log('SummarizationStore.clear')

        this._initialMessage = undefined
        this._openClearDialog = false
        this._serviceName = Constants.Services.Chat
        // this._conversationStore?.clear()
        // this._modelSelectionStore?.clear()
        // this._promptSuggestionsStore?.clear()
    }
}
