import React, { useState, useRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ReactMarkdown from 'react-markdown';
import { Box, useTheme, styled, keyframes } from '@mui/material';
import { observer } from 'mobx-react';

import rehypeRaw from "rehype-raw";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface ReleaseNotesDialogProps {
    open: boolean;
    title: string;
    url?: string;
    buttonOnCloseText: string;
    onClose: () => void;
}

const fetchReleaseNotes = async (url?: string): Promise<string> => {
    if (!url) return Promise.resolve('No terms of service URL provided');
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.text();
};

const ScrollIndicator: React.FC<{ isAtBottom: boolean }> = ({ isAtBottom }) => {
    if (isAtBottom) return null;

    return (
        <Box sx={{
            position: 'absolute',
            bottom: '20px',
            width: '100%',
            textAlign: 'center',
            pointerEvents: 'none', // Allows click events to pass through
        }}>
            <KeyboardArrowDownIcon color='secondary' sx={{ fontSize: 40 }} />
        </Box>
    );
};

const ReleaseNotesDialog: React.FC<ReleaseNotesDialogProps> = observer(({ open, title, url, buttonOnCloseText, onClose }) => {
    const [isAgreeButtonDisabled, setIsAgreeButtonDisabled] = useState(true);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const theme = useTheme();

    //const termsOfServiceMarkdown = "# Nutzungsbedingungen für die Nutzung von blue.gpt(Instanz 2)  "
    const [markdownContent, setMarkDownContent] = useState('');

    useEffect(() => {
        fetchReleaseNotes(url)
            .then(markdown => {
                setMarkDownContent(markdown);

                // Delay the initial check until after the component has mounted
                const timer = setTimeout(() => {
                    checkScroll();
                }, 100);

                const element = contentRef.current;
                if (element) {
                    element.addEventListener('scroll', checkScroll);
                }


                return () => {
                    clearTimeout(timer);
                    if (element) {
                        element.removeEventListener('scroll', checkScroll);
                    }
                };
            })
            .catch(error => console.error('Error fetching terms of service:', error));
    }, [url]);


    const handleScroll = () => {
        const element = contentRef.current;
        if (!element) return;

        // console.log(element.scrollHeight - element.scrollTop - 1)
        // console.log(element.clientHeight)
        // console.log((element.scrollHeight - element.scrollTop - 1) <= element.clientHeight)

        const bottom = (element.scrollHeight - element.scrollTop - 1) <= element.clientHeight;
        if (bottom) {
            setIsAgreeButtonDisabled(false);
        }
    };

    const checkScroll = () => {
        const element = contentRef.current;
        if (!element) return;

        const isScrollable = element.scrollHeight > element.clientHeight;
        let scrollDiff = (element.scrollHeight - element.scrollTop - 1)
        const isAtBottom = scrollDiff <= element.clientHeight;
        // console.log('isScrollable', isScrollable)
        // console.log('isAtBottom', isAtBottom)
        setIsAtBottom(isAtBottom);
        setIsAgreeButtonDisabled(isScrollable && !isAtBottom);
    };



    const bounce = keyframes`
    0 %, 20 %, 50 %, 80 %, 100 % { transform: translateY(0); }
    40 % { transform: translateY(-10px); }
    60 % { transform: translateY(-5px); }
        `;



    const CustomScrollBox = styled(Box)({
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.background.default,
        },
        overflowY: 'scroll',
        overflowX: 'auto',
        scrollbarWidth: 'thin',
        msOverflowStyle: 'none', // IE and Edge
        '::-webkit-scrollbar': { // Webkit browsers
            display: 'none',
        }
    });


    return (

        <Dialog open={open} maxWidth="md" scroll="paper">
            <DialogTitle sx={{ color: theme.palette.primary.main }}>{title}</DialogTitle>
            <DialogContent dividers={true} ref={contentRef} onScroll={handleScroll} style={{ height: '400px' }}>
                <CustomScrollBox sx={{ color: theme.palette.text.primary }}>
                    <ReactMarkdown children={markdownContent} rehypePlugins={[rehypeRaw]} />
                </CustomScrollBox>
                <ScrollIndicator isAtBottom={isAtBottom} />
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{ animation: isAgreeButtonDisabled ? 'none' : `${bounce} 1s ease infinite` }}
                    onClick={onClose}
                    color="primary" variant="contained">
                    {buttonOnCloseText}
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default ReleaseNotesDialog;