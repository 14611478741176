import { Constants } from "../../../contants/Constants";
import { SidebarSubMenuItem } from "../../../layouts/Dashboard/types";
import { IFeaturePlugin, IFeaturePluginStore, InitFunction, PluginInfo } from "../../../plugin/types";
import { RootStore } from "../../../stores/RootStore";
import { QuickActions } from "../../../types/ChatTypes";
import { SideBarView } from "../../../types/UITypes";
import { AskQuestionStreamingMessage } from "../../../types/WebletServiceTypes";
import { chatHistorySidebarElement, documentChatFeatureNode } from "../components/DocumentChatFeatureNode";
import { documentChatMenuItemFactory } from "../components/DocumentChatSidebarMenu";
import { DocumentChatStore } from "../stores/DocumentChatStore";

import i18n from '../../../i18n';

export class DocumentChatFeaturePlugin implements IFeaturePlugin {

    _rootStore: RootStore | undefined = undefined;
    _initialized: boolean;
    _pluginStore: DocumentChatStore | undefined = undefined
    _sidebarViews: SideBarView[] = []

    constructor(rootStore: RootStore) {

        this._initialized = false;
        this._rootStore = undefined;
        this._rootStore = rootStore
        //this.initialize(rootStore)
    }

    get featureStore(): IFeaturePluginStore | undefined {
        return this._pluginStore as IFeaturePluginStore
    }

    initialize(store: RootStore): void {
        if (this._initialized === false) {
            this._initialized = true;
            this._rootStore = store;
            this._pluginStore = new DocumentChatStore(store)
            this._rootStore.addFeaturePlugin(this)
        }
    }

    activated(): void {
        if (this._rootStore !== undefined) {
            console.log("ChatFeaturePlugin.initialize: setting active plugin")
            this._rootStore.setActivePlugin(this as IFeaturePlugin)
        }
        if (this._pluginStore !== undefined) {
            this._pluginStore.initialize()
        }

        this._rootStore?.fireUpdateUI()
    }

    get initialized(): boolean {
        return this._initialized;
    }

    get name(): string {
        return "documentchat";
    }

    enabled(): boolean {
        return true;
    }

    menuItems(initFunction: InitFunction): SidebarSubMenuItem {

        let menuItem: SidebarSubMenuItem = documentChatMenuItemFactory(initFunction);
        return menuItem as SidebarSubMenuItem;
    }

    route(): string {
        return Constants.Routes.DocumentChat
    }

    node(): React.ReactNode {
        if (this._pluginStore !== undefined) {
            let quickAction = this.quickActions()

            let onFeedbackThumbUp = undefined
            let onFeedbackThumbDown = undefined

            if (this._pluginStore._streamingChatStore._conversationStore !== undefined) {
                onFeedbackThumbDown = this._pluginStore._streamingChatStore._conversationStore.onFeedbackThumbDown.bind(this._pluginStore._streamingChatStore._conversationStore)
                onFeedbackThumbUp = this._pluginStore._streamingChatStore._conversationStore.onFeedbackThumbUp.bind(this._pluginStore._streamingChatStore._conversationStore)
            }
            let node = documentChatFeatureNode(
                this._pluginStore,
                quickAction,
                onFeedbackThumbUp,
                onFeedbackThumbDown
            )
            return node
        }
        return null
    }

    onMessage(data: string): void {

        const message = JSON.parse(data) as AskQuestionStreamingMessage
        console.log('DocumentChatFeaturePlugin.onMessage: ', message)
        if (message.service === Constants.Services.DocChat) {
            if (message.status === 'completed') {
                this._pluginStore?._streamingChatStore.onMessage(message)
                this._pluginStore?._streamingChatStore.onCompleted()
                this._pluginStore?._streamingChatStore.setNewChatAllowed(true)
            } else if (message.status === 'error') {

                this._pluginStore?._streamingChatStore.onMessage(message)
                // show error message
                this._rootStore?.notify(i18n.t('errorMessages.chat.error'), { variant: 'error' })
                //this._pluginStore?._streamingChatStore.setNewChatAllowed(true)
                this._pluginStore?.handleStreamingError()
            } else {

                this._pluginStore?._streamingChatStore.onMessage(message)
            }
        }
    }

    quickActions(): QuickActions[] {
        let quickActions: QuickActions[] = []
        if (this._pluginStore !== undefined) {

            quickActions.push({
                title: i18n.t('feature.docChat.buttonUpload.title'),
                action: () => {
                    this._pluginStore?.quickActionUploadDocument()
                }
            } as QuickActions)

            quickActions.push({
                title: i18n.t('feature.docChat.buttonClearChatHistory'),
                action: () => {
                    this._pluginStore?.quickActionClearChatHistory()
                }
            } as QuickActions)

            quickActions.push(
                {
                    title: i18n.t('feature.docChat.buttonDeleteDocument'),
                    action: () => {

                        this._pluginStore?.quickActionDeleteDocument()
                    }
                } as QuickActions
            )
        }
        return quickActions
    }

    get sidebarViews(): SideBarView[] {

        this._sidebarViews = []
        if (this._pluginStore !== undefined) {

            if (this._pluginStore._streamingChatStore._conversationStore !== undefined) {
                let chatHistory = chatHistorySidebarElement(
                    i18n.t('feature.docChat.sidebar.chatHistory'),
                    this._pluginStore,
                    this._pluginStore._streamingChatStore._conversationStore.onConversationSelected.bind(this._pluginStore._streamingChatStore._conversationStore),
                    this._pluginStore._streamingChatStore._conversationStore.onConversationDeleted.bind(this._pluginStore._streamingChatStore._conversationStore),
                    this._pluginStore._streamingChatStore._conversationStore.updateConversationTitle.bind(this._pluginStore._streamingChatStore._conversationStore))
                this._sidebarViews.push(chatHistory)
            }

            // let chatSettings = chatSettingsSidebarElement(
            //     i18n.t('feature.chat.sidebar.chatSettings'),
            //     this._pluginStore)
            // this._sidebarViews.push(chatSettings)
        }
        return this._sidebarViews
    }

    onNewButton() {
        this._pluginStore?.onNewChat()
    }

    pluginInfo(): PluginInfo {
        return {
            title: i18n.t('feature.docChat.description.title'),
            description: i18n.t('feature.docChat.description.text'),
            version: '1.0.0'
        } as PluginInfo
    }

    get newInteractionAllowed(): boolean {
        if (this._pluginStore === undefined) {
            return false;
        }
        return this._pluginStore._streamingChatStore.newChatAllowed
    }
}