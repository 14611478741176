import './App.css';

import './i18n';

import { Route, Routes, useNavigate } from 'react-router-dom';
import { Dashboard } from './layouts/Dashboard';
import { Box } from '@mui/material';
import { Constants } from './contants/Constants';
import React, { useContext, useEffect } from 'react';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { useTranslation } from 'react-i18next';
import { SidebarMenuItem, SidebarSubMenuItem } from './layouts/Dashboard/types';
import { IFeaturePlugin } from './plugin/types';
import { UserMenuOptions } from './types/UITypes';
import { observer } from 'mobx-react-lite';
import RootStoreContext from './contexts/RootStoreProvider';
import NotFound from './components/NotFoundPage';
import AuthRedirect from './auth/AuthRedirect';
import UserStoreContext from './contexts/UserStoreProvider';
import { GlobalDebug } from './utils/GlobalDebug';
import { getFeaturePlugins } from './plugin/featureFactory';

interface PagesRoutesProps {
  authenticationGuard: React.ReactNode
  loginComponent: React.ReactNode
  userMenuOptions: UserMenuOptions[]
}


const PagesRoutes: React.FC<PagesRoutesProps> = ({ authenticationGuard, loginComponent, userMenuOptions }) => {

  const { t, i18n } = useTranslation();
  const { rootStore } = useContext(RootStoreContext)
  const { userStore } = useContext(UserStoreContext)
  const onlyAppLogo = process.env.REACT_APP_ONLY_COMPANY_LOGO === 'true'
  const navigate = useNavigate()

  const [selectableTools, setSelectableTools] = React.useState<SidebarSubMenuItem[]>([])



  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false, true);

    if (userStore !== undefined) {
      if (rootStore !== undefined) {
        rootStore.userStore = userStore
      }

      userStore.rootStore = rootStore

      let checkUserLogIn = async () => {
        let userLogin = await userStore.checkUserLogIn()
        if (userLogin) {
          console.log(rootStore)
          if (rootStore !== undefined) {

            let featurePlugins = getFeaturePlugins(rootStore)
            if (featurePlugins.length > 0) {

              console.log('initializePlugins')
              rootStore.initializePlugins(featurePlugins)
              rootStore.setPlugins(featurePlugins)
            }
          }

          if (rootStore?.plugins !== undefined) {
            let tools: SidebarSubMenuItem[] = []
            rootStore?.plugins.forEach((feature: IFeaturePlugin) => {

              let item: SidebarSubMenuItem = feature.menuItems(feature.activated.bind(feature))
              if (item !== undefined) {
                tools.push(item)
              }
            })
            setSelectableTools(tools)
          }
          navigate('/chat')
        } else {
          console.log("useEffect:User not logged in")

        }
      }

      checkUserLogIn()
    }

    console.log("useEffect Pages")

  }, [userStore?.isLoggedIn]);





  // console.log("PagesRoutes: ", rootStore?.plugins)
  return (
    <>
      {process.env.REACT_APP_PROD === 'true' ?
        <Routes>
          <Route path={Constants.Routes.Login} element={loginComponent} />
          <Route element={authenticationGuard}>
            <Route path={Constants.Routes.Home} element={
              <Box sx={{ display: 'flex', height: '100vh' }}>
                <Dashboard selectableTools={selectableTools}
                  onlyAppLogo={onlyAppLogo}
                  userMenuOptions={userMenuOptions} />
              </Box>
            } >
              {rootStore && rootStore.plugins !== undefined && rootStore.plugins.map((feature: IFeaturePlugin) => {
                console.log(feature.route())
                return (<Route key={feature.name} path={feature.route()} element={feature.node()} />)
              })}
            </Route>
          </Route>
          <Route path="/auth-redirect" element={<AuthRedirect />} />
          <Route path="/*" element={<NotFound />} />
        </Routes >
        :
        <Routes>
          <Route path={Constants.Routes.Home} element={
            <Box sx={{ display: 'flex', height: '100vh' }}>
              <Dashboard selectableTools={selectableTools}
                onlyAppLogo={true}
                userMenuOptions={userMenuOptions} />
            </Box>} >
            {rootStore && rootStore.plugins !== undefined && rootStore.plugins.map((feature: IFeaturePlugin) => {
              console.log(feature.route())
              return (<Route key={feature.name} path={feature.route()} element={feature.node()} />)
            })}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      }
    </>
  );
}

export default observer(PagesRoutes);
