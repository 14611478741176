
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Conversation } from '../../types/types_api';
import { ButtonBase, Skeleton, TextField, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { YesNoDialog } from '../YesNoDialog/YesNoDialog';
import { useTranslation } from 'react-i18next';



export interface ChatHistoryProps {
    loading: boolean
    conversations: Conversation[]
    onConversationSelected?: (conversationId: string) => void;
    onConversationDeleted?: (conversationId: string) => void;
    onConversationTitleChanged?: (conversationId: string, title: string) => void;
}

const ChatHistory: React.FC<ChatHistoryProps> = ({ loading, conversations, onConversationSelected,
    onConversationDeleted,
    onConversationTitleChanged }) => {

    const [open, setOpen] = React.useState(false);
    const [conversationId, setConversationId] = React.useState<string>('');

    const [tempTitle, setTempTitle] = React.useState(''); // Temporary title while editing
    const [editingConversationId, setEditingConversationId] = React.useState<string | null>(null);

    const { t, i18n } = useTranslation();

    const onYes = () => {

        if (onConversationDeleted) {
            onConversationDeleted(conversationId)
        }
        setOpen(false);
        setConversationId('');
    }

    const onNo = () => {
        setOpen(false);
        setConversationId('');
    }

    let exampleConversation = Array.from(Array(20).keys())

    return (
        <>
            {loading == false &&
                < List dense={true} sx={{ m: 1, p: 1 }}>
                    {conversations.map((conversation) => {
                        let textComponent = (
                            <Typography
                                sx={{
                                    ml: 0,
                                    width: '100%',
                                    flexGrow: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                                textAlign={'left'}
                            >
                                Unamed conversation...
                            </Typography>
                        );

                        if (conversation.title) {
                            textComponent = (
                                <Typography
                                    sx={{
                                        ml: 0,
                                        width: '100%',
                                        flexGrow: 1,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                    textAlign={'left'}
                                >
                                    {conversation.title.slice(0, 20)}...
                                </Typography>
                            );
                        }

                        return (
                            <ListItem sx={{ pl: 0, pt: 0 }} key={conversation.id} dense={true}>

                                <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                        {
                                            editingConversationId === conversation.id ? (
                                                <TextField
                                                    value={tempTitle}
                                                    onChange={e => setTempTitle(e.target.value)}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ mt: 0, mb: 0 }}
                                                />
                                            ) : (
                                                <ButtonBase sx={{ width: '100%' }} onClick={() => {
                                                    console.log('onConversationSelected', conversation.id);
                                                    if (onConversationSelected) {
                                                        onConversationSelected(conversation.id);
                                                    }
                                                }}>
                                                    <ListItemText sx={{ ml: 0, width: '100%', flexGrow: 1 }}
                                                        primary={textComponent}
                                                    />
                                                </ButtonBase>
                                            )
                                        }
                                    </Grid>

                                    <Grid item xs={3}>
                                        {
                                            editingConversationId === conversation.id ? (
                                                <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} alignContent={'center'}>
                                                    <IconButton edge="end" aria-label="confirm" onClick={() => {
                                                        conversation.title = tempTitle;

                                                        if (onConversationTitleChanged) {
                                                            onConversationTitleChanged(conversation.id, tempTitle);
                                                        }

                                                        setEditingConversationId(null);
                                                    }}>
                                                        <CheckIcon />
                                                    </IconButton>
                                                    <IconButton edge="end" aria-label="cancel" onClick={() => {
                                                        setEditingConversationId(null);
                                                    }}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Box>
                                            ) : (
                                                <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} alignContent={'center'}>
                                                    <IconButton edge="end" aria-label="edit" onClick={() => {
                                                        setTempTitle(conversation.title || '');
                                                        setEditingConversationId(conversation.id);
                                                    }}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton edge="end" aria-label="delete" onClick={() => {
                                                        setConversationId(conversation.id);
                                                        setOpen(true);
                                                    }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            )
                                        }
                                    </Grid>
                                </Grid>

                            </ListItem>
                        );
                    })}
                </List >
            }
            {loading == true &&
                < List dense={true} sx={{ m: 1, p: 1 }}>

                    <>
                        {exampleConversation.map((conversation, index: number) => {
                            return (
                                <Skeleton key={`conv-${index}`} variant="rounded" width={'100%'} >
                                    < Typography
                                        sx={{
                                            m: 1,
                                            ml: 0,
                                            width: '100%',
                                            flexGrow: 1,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}
                                        textAlign={'left'}
                                    >
                                        Unamed conversation...
                                    </Typography>
                                </Skeleton>)
                        }
                        )
                        }
                    </>

                </List >

            }


            <YesNoDialog open={open}
                title={t('conversation.title')}
                content={t('conversation.message')}
                yesButton={t('conversation.buttonConfirm')}
                noButton={t('conversation.buttonCancel')}
                onYes={onYes}
                onNo={onNo}
                colorButtonNo={true} />
        </>
    );
}

export default observer(ChatHistory);