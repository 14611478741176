import { UserResponse } from "../../types/types_api";
import { RootStore } from "../RootStore";
import { action, makeAutoObservable, observable } from 'mobx';
import { IUserStore, loginCallback } from "../interfaces/IUserStore";
import { EventType, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { b2cPolicies } from "../../auth/msal/authConfig";
import { compareIssuingPolicy } from "../../auth/msal/claimUtils";

/**
 * Description placeholder
 * @date 25/09/2023 - 07:39:51
 *
 * @export
 * @class UserStoreMSAL
 * @typedef {UserStoreMSAL}
 * @implements {IUserStore}
 */
export class UserStoreMSAL implements IUserStore {

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:51
     *
     * @type {(RootStore | undefined)}
     */
    _rootStore: RootStore | undefined = undefined;



    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:51
     *
     * @type {boolean}
     */
    @observable
    _isLoggedIn: boolean = false

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:51
     *
     * @type {string}
     */
    //@observable
    _accessToken: string = ''

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {?UserResponse}
     */
    //@observable
    _currentUser?: UserResponse;

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {(string | undefined)}
     */
    @observable
    _error: string | undefined = undefined

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {boolean}
     */
    @observable
    _loading: boolean = false

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {(PublicClientApplication | undefined)}
     */
    _instance: PublicClientApplication | undefined = undefined

    _msalReady: boolean = false

    /**
     * Creates an instance of UserStoreMSAL.
     * @date 25/09/2023 - 07:39:50
     *
     * @constructor
     */
    constructor() {
        console.log('UserStoreMSAL: constructor')
        this._instance = undefined
        makeAutoObservable(this);
    }

    setMsalReady(msalReady: boolean) {
        this._msalReady = msalReady
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {*}
     */
    set rootStore(rootStore: RootStore | undefined) {
        this._rootStore = rootStore
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {(RootStore | undefined)}
     */
    get rootStore(): RootStore | undefined {
        return this._rootStore
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {(PublicClientApplication | undefined)}
     */
    get instance(): PublicClientApplication | undefined {
        return this._instance
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @type {*}
     */
    set instance(instance: PublicClientApplication | undefined) {
        this._instance = instance
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @readonly
     * @type {boolean}
     */
    get isLoggedIn(): boolean {
        return this._isLoggedIn
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @param {boolean} isLoggedIn
     */
    @action
    setLoggedIn(isLoggedIn: boolean): void {
        this._isLoggedIn = isLoggedIn
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @readonly
     * @type {string}
     */
    get accessToken(): string {

        return "undefined"
    }

    /**
     * Retrieves the current access token from the MSAL instance
     * @date 25/09/2023 - 07:39:50
     *
     * @async
     * @returns {Promise<string | undefined>}
     */
    async getAccessToken(): Promise<string | undefined> {
        console.log('UserStoreMsal: getAccessToken')

        if (this._instance !== undefined) {
            const account = await this._instance.getAllAccounts()[0];
            console.log(account)
            const accessTokenRequest = {
                scopes: ['https://graph.microsoft.com/User.Read'],
                account: account,
            };
            try {
                let accessTokenReponse = await this._instance.acquireTokenSilent(accessTokenRequest)
                if (accessTokenReponse !== undefined) {
                    console.log(accessTokenReponse)
                    return Promise.resolve(accessTokenReponse.idToken)
                }
                return Promise.reject("accessTokenReponse unavailabe")

            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    try {
                        // save state
                        await this._instance.acquireTokenRedirect(accessTokenRequest)
                        // if (accessTokenResponse !== undefined) {
                        //     console.log(accessTokenResponse)
                        //     //return Promise.resolve(accessTokenResponse.idToken)
                        //     return Promise.resolve(accessTokenResponse.id)
                        // }

                    } catch (error) {
                        console.log(error)
                        return Promise.resolve(undefined)
                    }
                    console.log(error);
                }
                return Promise.resolve(undefined)
            }
        } else {
            return Promise.resolve(undefined)
        }
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @param {string} accessToken
     */
    @action
    setAccessToken(accessToken: string): void {
        console.log('UserStoreMsal: setAccessToken')
        console.log(accessToken)
        this._accessToken = accessToken
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     */
    @action
    clear() {
        this._accessToken = ''
        this._isLoggedIn = false
        this._currentUser = undefined
        this._error = undefined
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @param {UserResponse} user
     */
    @action
    setUser(user: UserResponse) {
        this._currentUser = user;
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @readonly
     * @type {(UserResponse | undefined)}
     */
    get currentUser(): UserResponse | undefined {
        return this._currentUser
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     */
    @action
    clearUser() {
        this._currentUser = undefined;
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @readonly
     * @type {(string | undefined)}
     */
    get errorMessage(): string | undefined {
        return this._error
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @param {(string | undefined)} errorMessage
     */
    @action
    setErrorMessage(errorMessage: string | undefined) {
        this._error = errorMessage
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @param {boolean} loading
     */
    @action
    setLoading(loading: boolean) {
        this._loading = loading
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @readonly
     * @type {boolean}
     */
    get loading(): boolean {
        return false
    }


    async callback(event: any) {

        if (event.eventType === EventType.LOGIN_SUCCESS) {
            console.log('LOGIN_SUCCESS')
        }
        if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
            /**
             * For the purpose of setting an active account for UI update, we want to consider only the auth
             * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
             * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
             * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
             */

            if (this._instance !== undefined) {
                const originalSignInAccount = this._instance
                    .getAllAccounts()
                    .find(
                        (account: any) =>
                            account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                            account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                            account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn
                    );

                let signUpSignInFlowRequest = {
                    authority: b2cPolicies.authorities.signUpSignIn.authority,
                    account: originalSignInAccount,
                };
                console.log('originalSignInAccount')
                console.log(originalSignInAccount)

                let activeAccount = this._instance.getActiveAccount();
                console.log('activeAccount')
                console.log(activeAccount)
                if (activeAccount) {
                    this.setLoggedIn(true)
                    this.setUser({
                        guid: activeAccount.localAccountId,
                        username: activeAccount.username
                    } as UserResponse)
                }


                // try {
                //     // silently login again with the signUpSignIn policy

                //     await this._instance.ssoSilent({
                //         authority: b2cPolicies.authorities.signUpSignIn.authority,
                //         account: originalSignInAccount,
                //         prompt: 'none'
                //     });
                //     console.log(originalSignInAccount)
                //     this.setLoggedIn(true)
                // } catch (error) {
                //     console.log(error)
                //     if (this._instance !== undefined) {
                //         this._instance.loginPopup();
                //     }
                // }
            }
            let signUpSignInFlowRequest_2 = {
                authority: b2cPolicies.authorities.signUpSignIn.authority,
                account: undefined

            };
            /**
             * Below we are checking if the user is returning from the reset password flow.
             * If so, we will ask the user to reauthenticate with their new password.
             * If you do not want this behavior and prefer your users to stay signed in instead,
             * you can replace the code below with the same pattern used for handling the return from
             * profile edit flow
             */
            if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                let signUpSignInFlowRequest = {
                    authority: b2cPolicies.authorities.signUpSignIn.authority,
                    scopes: [],
                };
                this._instance?.loginPopup(signUpSignInFlowRequest);
            }
            // let signUpSignInFlowRequest = {
            //     authority: b2cPolicies.authorities.signUpSignIn.authority,
            //     scopes: [],
            // };
            // if (this._instance !== undefined) {
            //     this._instance.log(signUpSignInFlowRequest);
            // }

            console.log('ACQUIRE_TOKEN_SUCCESS')
        }
        else if (event.eventType === EventType.HANDLE_REDIRECT_START) {
            console.log('HANDLE_REDIRECT_START')

        } else if (event.eventType === EventType.HANDLE_REDIRECT_END) {

            console.log('HANDLE_REDIRECT_END')
        } else if (event.eventType === EventType.INITIALIZE_END) {
            console.log('INITIALIZE_END')
            if (this._instance !== undefined) {
                this.setMsalReady(true)
            }
        } else if (event.eventType === EventType.RESTORE_FROM_BFCACHE) {
            console.log('RESTORE_FROM_BFCACHE')
        } else if (event.eventType === EventType.SSO_SILENT_START) {
            console.log('SSO_SILENT_START')
        } else if (event.eventType === EventType.SSO_SILENT_SUCCESS) {
            console.log('SSO_SILENT_SUCCESS')
            this.setLoggedIn(true)
        }
    }

    @action
    async login(username: string, password: string, loginCallback?: loginCallback): Promise<boolean | undefined> {
        console.log('login userStoreMSAL')
        console.log(this._instance)

        if (this._instance !== undefined) {
            try {
                // console.log('login loginPopup')
                //let callbackId = this._instance.addEventCallback(this.callback.bind(this))
                await this._instance.loginRedirect();
                // if (callbackId !== null) {
                //     this._instance.removeEventCallback(callbackId)
                // }

            } catch (error) {
                console.log(error)
                this.setErrorMessage('Error logging in. Please try again.')
            }
        }
        return Promise.resolve(true)
    }


    @action
    async logout() {
        if (this._instance !== undefined) {
            await this._instance.logoutRedirect();
            await this._instance.clearCache();
        }
    }

    async checkUserLogIn(): Promise<boolean> {

        // if (this._instance !== undefined) {
        //     if (this._msalReady === false) {
        //         return Promise.resolve(false)
        //     }
        //     const account = await this._instance.getAllAccounts()[0];
        //     console.log(account)
        //     const accessTokenRequest = {
        //         scopes: [],
        //         account: account,
        //     };
        //     let accessTokenReponse = await this._instance.acquireTokenSilent(accessTokenRequest)
        //     if (accessTokenReponse !== undefined) {
        //         console.log(accessTokenReponse)
        //         return Promise.resolve(true)
        //     }
        // }
        return Promise.resolve(this.isLoggedIn)
    }

    /**
     * Description placeholder
     * @date 25/09/2023 - 07:39:50
     *
     * @async
     * @returns {Promise<void>}
     */
    async getUser(): Promise<void> {
        if (this._instance) {
            let activeAccount = this._instance.getActiveAccount();
            if (activeAccount) {

                let currentUser = {
                    guid: activeAccount.localAccountId,
                    username: activeAccount.username
                } as UserResponse
                this.setUser(currentUser)
            }
        }
    }
}