import React, { useContext, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router';
import { EventType } from '@azure/msal-browser';
import UserStoreContext from '../contexts/UserStoreProvider';

const AuthRedirect = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();
    const { userStore } = useContext(UserStoreContext);

    const handleRedirectPromise = async () => {
        try {
            const response = await instance.handleRedirectPromise();
            if (response) {
                console.log('handleRedirectPromise', response);
            } else {
                //navigate('/login'); // Handle error scenarios.
            }
        } catch (error) {
            console.error('Error handling redirect:', error);
            navigate('/login'); // Redirect to login or an error page.
        }
    };

    useEffect(() => {
        if (!instance) return;

        const callbackId = instance.addEventCallback((event) => {
            switch (event.eventType) {
                case EventType.LOGIN_SUCCESS:
                    // console.log('LOGIN_SUCCESS');
                    // let accounts = instance.getAllAccounts()
                    // console.log('accounts', accounts)
                    // // Only attempt to acquire a token silently if there are accounts detected
                    // if (accounts.length > 0) {
                    //     const silentRequest = {
                    //         scopes: ["openid"], // Adjust with your own scopes
                    //         account: accounts[0]  // The account object you want to acquire the token for
                    //     };

                    //     instance.acquireTokenSilent(silentRequest)
                    //         .then(response => {
                    //             // Token acquisition succeeded
                    //             // Update state to indicate user is authenticated
                    //             console.log('acquireTokenSilent succeeded', response)

                    //             userStore?.setUser({
                    //                 guid: accounts[0].localAccountId,
                    //                 username: accounts[0].username
                    //             })
                    //             userStore?.setLoggedIn(true);

                    //             navigate('/chat'); // Navigate to the main page.

                    //         })
                    //         .catch(error => {
                    //             // if (error instanceof instance.InteractionRequiredAuthError) {
                    //             //     // This means interaction is required (i.e., user needs to sign in again)
                    //             //     // You can decide here if you want to redirect the user to login
                    //             //     navigate('/login')
                    //             // } else {
                    //             //     // Handle other potential errors here
                    //             // }
                    //         });
                    // }

                    break;
                case EventType.HANDLE_REDIRECT_START:
                    // console.log('HANDLE_REDIRECT_START');
                    break;
                case EventType.HANDLE_REDIRECT_END:
                    // console.log('HANDLE_REDIRECT_END');
                    break;
                case EventType.INITIALIZE_END:
                    // console.log('INITIALIZE_END');
                    break;
                // Add other event types as needed...
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId); // Cleanup event callback on unmount.
            }
        };
    }, [instance, navigate, userStore]);

    return (
        <div>
            Processing authentication...
        </div>
    );
};

export default AuthRedirect;
