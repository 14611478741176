import axios, { AxiosInstance } from 'axios';
import {
    AskQuestionStreamingResponseModel
} from '../types/WebletServiceTypes';

import { Conversation, Message } from '../types/types_api';
import { errorHandler } from './ErrorHandler';


class ConversationService {

    private client: AxiosInstance;

    constructor(baseURL: string, service: string = '') {

        this.client = axios.create({ baseURL: baseURL + `/api` });
    }

    async createConversation(userId: string, serviceName: string, model: string, title: string, token?: string): Promise<Conversation | undefined> {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true
        };

        let body = {
            user_id: userId,
            title: title,
            model: model,
            service: serviceName,
        }

        try {
            const response = await this.client.post<Conversation>(`/conversations`, body, config);
            // console.log(response.data);
            return response.data;
        } catch (error) {
            throw error
        }
    }

    async getConversations(userId: string, token?: string): Promise<Conversation[]> {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true
        };

        try {
            const response = await this.client.get<Conversation[]>(`/conversations/${userId}`, config);
            // console.log(response.data);
            return Promise.resolve(response.data as Conversation[]);
        } catch (error) {
            throw error
        }
    }

    async getConverstationMessages(conversationId: string, token?: string): Promise<Message[]> {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true
        };

        try {
            const response = await this.client.get<Message[]>(`/conversations/${conversationId}/messages`, config);
            //console.log(response.data);
            return response.data;
        } catch (error) {
            throw error
        }
    }

    async deleteConversation(conversationId: string, token?: string): Promise<AskQuestionStreamingResponseModel> {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true
        };

        let body = {

        }

        try {
            const response = await this.client.put<AskQuestionStreamingResponseModel>(`/conversations/${conversationId}`, body, config);
            // console.log(response.data);
            return response.data;
        } catch (error) {
            throw error
        }
    }

    async updateConversationTitle(conversationId: string, title: string, token?: string): Promise<AskQuestionStreamingResponseModel> {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true
        };

        let body = {

        }

        try {
            const response = await this.client.put<AskQuestionStreamingResponseModel>(`/conversations/${conversationId}/title?title=${title}`, body, config);
            // console.log(response.data);
            return response.data;
        } catch (error) {
            throw error
        }
    }

    async setFeedback(messageId: string, feedback: boolean, token?: string): Promise<AskQuestionStreamingResponseModel> {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true
        };

        let body = {
        }
        let feedBackString = feedback ? 'true' : 'false'
        try {
            const response = await this.client.post<AskQuestionStreamingResponseModel>(`/conversations/${messageId}/feedback?feedback=${feedBackString}`, body, config);
            console.log(response.data);
            return response.data;
        } catch (error) {
            throw error
        }
    }
}

export const conversationService = new ConversationService(
    process.env.REACT_APP_BACKEND_URL ?? "http://localhost:3001",
    process.env.REACT_APP_SERVICE ?? "cognitive"
);
