

import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';

import { Outlet } from 'react-router-dom';

const RouteAuthenticationGuardMsal = ({ }) => {

    return (<AuthenticatedTemplate>
        <Outlet />
    </AuthenticatedTemplate >)
}

export default RouteAuthenticationGuardMsal
