
import ThemeTest from './themeTest';
import ThemeGFT from './theme';
import ThemeBlue from './themeBlue';
import ThemeMMake from './themeMMake';

interface Theme {
    // Define your theme structure here
    // For example: colors, typography, spacing, etc.
}

interface ThemeMap {
    [key: string]: Theme;
}

const themeMap: ThemeMap = {
    TestApp: ThemeTest,
    GFT: ThemeGFT,
    LBBW: ThemeBlue,
    MMake: ThemeMMake,
};

const selectedTheme = process.env.REACT_APP_NAME || 'GFT';

export const getTheme = () => themeMap[selectedTheme];
