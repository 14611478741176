import { Constants } from "../../../contants/Constants";
import { SidebarSubMenuItem } from "../../../layouts/Dashboard/types";
import { IFeaturePlugin, IFeaturePluginStore, InitFunction, PluginInfo } from "../../../plugin/types";
import { RootStore } from "../../../stores/RootStore";
import { QuickActions } from "../../../types/ChatTypes";
import { AskQuestionStreamingMessage } from "../../../types/WebletServiceTypes";
import { chatFeatureNode, chatHistorySidebarElement } from "../components/SummarizationFeatureNode";

import { chatMenuItemFactory, } from "../components/SummarizationSidebarMenu";
import { SummarizationStore } from "../stores/SummarizationStore";

import i18n from '../../../i18n';
import { SideBarView } from "../../../types/UITypes";

export class SummarizationPlugin implements IFeaturePlugin {

    _rootStore: RootStore | undefined = undefined;
    _initialized: boolean;
    _pluginStore: SummarizationStore | undefined = undefined
    _sidebarViews: SideBarView[] = []

    constructor(rootStore: RootStore) {
        this._initialized = false;
        this._rootStore = undefined;
        this._rootStore = rootStore
        //this.initialize(rootStore)
    }

    get featureStore(): IFeaturePluginStore | undefined {
        return this._pluginStore as IFeaturePluginStore
    }

    initialize(store: RootStore): void {
        if (this._initialized === false) {
            this._initialized = true;
            this._rootStore = store;
            this._pluginStore = new SummarizationStore(store)
            this._rootStore.addFeaturePlugin(this)
        }
    }

    activated(): void {
        if (this._rootStore !== undefined) {
            console.log("ChatFeaturePlugin.initialize: setting active plugin")
            this._rootStore.setActivePlugin(this as IFeaturePlugin)
        }
        if (this._pluginStore !== undefined) {
            this._pluginStore.initialize()
        }
        this._rootStore?.fireUpdateUI()
    }

    get initialized(): boolean {
        return this._initialized;
    }

    get name(): string {
        return "summaries";
    }

    enabled(): boolean {

        return true;
    }

    menuItems(initFunction: InitFunction): SidebarSubMenuItem {
        let menuItem: SidebarSubMenuItem = chatMenuItemFactory(initFunction);
        return menuItem as SidebarSubMenuItem;
    }

    route(): string {
        return Constants.Routes.Summaries
    }

    node(): React.ReactNode {
        if (this._pluginStore !== undefined) {
            let quickAction = this.quickActions()
            let onFeedbackThumbUp = undefined
            let onFeedbackThumbDown = undefined

            let node = chatFeatureNode(this._pluginStore,
                quickAction,
                onFeedbackThumbUp,
                onFeedbackThumbDown
            )
            return node
        }
    }

    onMessage(data: string): void {
        // const message = JSON.parse(data) as AskQuestionStreamingMessage

        // if (message.service === Constants.Services.Chat) {
        //     if (message.status === 'completed') {
        //         this._pluginStore?.onMessage(message)
        //         this._pluginStore?.onCompleted()
        //     } else {
        //         this._pluginStore?.onMessage(message)
        //     }
        // }
    }

    quickActions(): QuickActions[] {
        let quickActions: QuickActions[] = []
        if (this._pluginStore !== undefined) {
            quickActions.push({
                title: i18n.t('feature.chat.buttonClearChatHistory'),
                action: () => {
                    this._pluginStore?.quickActionClearChatHistory()
                }
            } as QuickActions)
        }
        return quickActions
    }

    get sidebarViews(): SideBarView[] {

        this._sidebarViews = []
        if (this._pluginStore !== undefined) {

            // if (this._pluginStore._conversationStore !== undefined) {
            //     let chatHistory = chatHistorySidebarElement(
            //         i18n.t('feature.chat.sidebar.chatHistory'),
            //         this._pluginStore,
            //         this._pluginStore._conversationStore.onConversationSelected.bind(this._pluginStore._conversationStore),
            //         this._pluginStore._conversationStore.onConversationDeleted.bind(this._pluginStore._conversationStore))
            //     this._sidebarViews.push(chatHistory)

            // }

            // let chatSettings = chatSettingsSidebarElement(
            //     i18n.t('feature.chat.sidebar.chatSettings'),
            //     this._pluginStore)
            // this._sidebarViews.push(chatSettings)
        }
        return this._sidebarViews
    }

    onNewButton() {
        console.log("onNewButton Summarization")
        this._pluginStore?.onNewChat()
    }

    pluginInfo(): PluginInfo {
        return {
            title: i18n.t('feature.summarization.description.title'),
            description: i18n.t('feature.summarization.description.text'),
            version: '1.0.0'
        } as PluginInfo
    }

    get newInteractionAllowed(): boolean {
        return true
    }
}
