
import { RootStore } from './RootStore';

import { Constants } from '../contants/Constants';
import { AskQuestionStreamingMessage } from '../types/WebletServiceTypes';
import { ChatMessageProps } from '../types/ChatTypes';

import { ConversationStore } from './ConversationStore';
import { ModelSelectionStore } from './ModelSelectionStore';
import { PromptSuggestionsStore } from './PromptSuggestionStore';
import { ContentStatus, IContentFilter, IContentFilterResult } from '../AIShield/AIShieldTypes';
import { action, makeAutoObservable, observable } from 'mobx';

import { v4 as uuidv4 } from 'uuid';
import i18n from '../i18n';
import { ReceiveParameters } from '../utils/serverside/ServerConnectionHandler';
import { chatService } from '../services/ChatService';
import axios from 'axios';
import { errorHandler } from '../services/ErrorHandler';


export interface HandleMesssageResult {
    message: string;
    contentStatus: ContentStatus;
    filterCategories?: string[];
    entities?: string[];
}

export interface IHandleSendMessage {
    handleSendMessage(newMessage: string): Promise<HandleMesssageResult>
}

export class StreamingChatStore {

    rootStore: RootStore;

    _serviceName: string = ''

    @observable
    messagesUI: ChatMessageProps[] = [];


    @observable
    _currentStreamingTokens: Array<string> = []

    _currentTokenIndex: number = 0

    _currentTokenList: Array<string> = []

    _timeoutId: NodeJS.Timeout | undefined = undefined
    _streamingCompleted: boolean = true

    _messageCounter: number = 0

    _currentMessage: ChatMessageProps

    _tokenStreamingRunning: boolean = false

    emptyMessage: ChatMessageProps = { message: "", sender: Constants.UI.BotName, timestamp: new Date().toISOString(), isUserMessage: false } as ChatMessageProps

    _conversationStore: ConversationStore | undefined = undefined

    _modelSelectionStore: ModelSelectionStore | undefined = undefined

    _promptSuggestionsStore: PromptSuggestionsStore | undefined = undefined

    @action
    _requestInProgress: boolean = false

    _runId: string | undefined = undefined

    @observable
    _newChatAllowed: boolean = true

    @observable
    _inputFieldDisabled: boolean = true

    _errorOnLastRequest: boolean = false

    @observable
    _initialMessage: string | undefined = undefined

    constructor(rootStore: RootStore, serviceName: string) {

        makeAutoObservable(this)
        this.rootStore = rootStore;
        this._currentStreamingTokens = []

        this._streamingCompleted = true
        this._messageCounter = 0
        this._currentMessage = this.emptyMessage
        this._serviceName = ''

        this._conversationStore = new ConversationStore(rootStore, this, rootStore._userStore)
        this._conversationStore.feedbackCallback = this.feedbackCallback.bind(this)
        this._modelSelectionStore = new ModelSelectionStore(rootStore)
        this._promptSuggestionsStore = new PromptSuggestionsStore(rootStore)
        this._requestInProgress = false
        this._newChatAllowed = false
        this._serviceName = serviceName
        this._initialMessage = ''
    }

    get inputFieldDisabled(): boolean {
        return this._inputFieldDisabled as boolean
    }

    setInputFieldDisabled(inputFieldDisabled: boolean) {
        this._inputFieldDisabled = inputFieldDisabled
    }

    get initialMessage(): string | undefined {
        return this._initialMessage
    }

    @action
    setInitialMessage(initialMessage: string | undefined) {
        //console.log('initialMessage', initialMessage)
        this._initialMessage = initialMessage
    }

    get newChatAllowed(): boolean {
        return this._newChatAllowed
    }

    setNewChatAllowed(newChatAllowed: boolean) {
        this._newChatAllowed = newChatAllowed
    }

    get requestInProgress(): boolean {
        return this._requestInProgress
    }

    @action
    setRequestInProgress(requestInProgress: boolean) {
        this._requestInProgress = requestInProgress
    }

    get conversationStore(): ConversationStore | undefined {
        return this._conversationStore
    }

    get modelSelectionStore(): ModelSelectionStore | undefined {
        return this._modelSelectionStore
    }

    get currentMessage() {
        return this._currentMessage
    }

    @action
    setCurrentMessage(currentMessage: ChatMessageProps) {
        this._currentMessage = currentMessage
    }

    @action
    setCurrentStreamingTokens(currentTokenList: Array<string>) {
        this._currentStreamingTokens = currentTokenList
    }

    @action
    appendCurrentStreamingTokens(currentTokenList: Array<string>) {
        this._currentStreamingTokens = this.currentStreamingTokens.concat(currentTokenList)
    }

    get currentStreamingTokens() {
        return this._currentStreamingTokens
    }

    async initialize() {

    }

    @action
    setMessagesUI(messagesUI: ChatMessageProps[]) {
        this.messagesUI = messagesUI
    }

    feedbackCallback(messageId: string, feedback: boolean) {
        //console.log(`feedbackCallback: (${this._serviceName})` + messageId)
        let newMessages = []
        for (let message of this.messagesUI) {
            if (message.id === messageId) {
                //console.log(`feedbackCallback: (${this._serviceName})` + messageId + ' found: feedback', feedback)
                message.feedback = feedback
            }
            newMessages.push(message)
        }
        this.setMessagesUI(newMessages)
    }


    addMessage(message: ChatMessageProps) {

        // let id = uuidv4()
        // message.id = id
        let newMessages = []
        for (let m of this.messagesUI) {
            newMessages.push(m)
        }

        newMessages.push(message)
        this.setMessagesUI(newMessages)
        // this.messagesUI = newMessages
    }

    getConversation(): Array<{ previous_statement: string; previous_answer: string }> {
        let conversation: Array<{ previous_statement: string; previous_answer: string }> = []
        let prevModel = {
            previous_statement: "",
            previous_answer: ""
        }
        this.messagesUI.forEach((message, idx) => {
            if (idx % 2 === 0) {
                prevModel.previous_statement = message.message
            } else {
                prevModel.previous_answer = message.message
                conversation.push(prevModel)
            }
        })
        return conversation;
    }

    getHistory(): Array<string> {
        return this.messagesUI.map((message) => message.message)
    }


    removeLastUserMessage() {
        if (this.messagesUI.length > 0) {
            let newMessages = this.messagesUI.slice(0, this.messagesUI.length - 1)
            console.log('removeLastUserMessage', newMessages)
            this.setMessagesUI(newMessages)
        }
    }

    async checkContent(newMessage: string): Promise<HandleMesssageResult> {

        if (newMessage === undefined || newMessage.trim().length === 0) {
            this.rootStore.notify(i18n.t('errorMessages.message.empty'), { variant: 'error' })
            return {
                message: newMessage,
                contentStatus: ContentStatus.Error,
                filterCategories: [],
                entities: []
            } as HandleMesssageResult
        }
        if (this.requestInProgress === true) {
            this.rootStore.notify(i18n.t('errorMessages.message.requestInProgress'), { variant: 'error' })
            return {
                message: newMessage,
                contentStatus: ContentStatus.Error,
                filterCategories: [],
                entities: []
            } as HandleMesssageResult
        }

        let contentFilter: IContentFilter = this.getContentFilter()
        let contentFilterResult: IContentFilterResult = await contentFilter.checkInput(newMessage);
        if (contentFilterResult.allowed === false) {
            let handleMessageResult: HandleMesssageResult = {
                message: newMessage,
                contentStatus: ContentStatus.Inappropriate,
                filterCategories: contentFilterResult.categories,
                entities: contentFilterResult.entities
            }
            return Promise.resolve(handleMessageResult)
        } else {
            let handleMessageResult: HandleMesssageResult = {
                message: newMessage,
                contentStatus: ContentStatus.Okay,
                filterCategories: []
            }
            return Promise.resolve(handleMessageResult)
        }
    }

    async sendMessage(newMessage: string): Promise<boolean> {

        let selectedModel = this._modelSelectionStore?._currentModel?.id
        if (selectedModel === undefined) {
            selectedModel = "gpt-35"
        }
        // console.log(selectedModel)
        let currentService = this._serviceName
        let history = this.getHistory()
        let conversationId = undefined
        if (this._conversationStore?._selectedConversation === undefined) {

            // create conversation
            let userId = this.rootStore.userStore?.currentUser?.guid
            let token = await this.rootStore.userStore?.getAccessToken()

            if (userId !== undefined && token !== undefined) {
                let maxTitleLength = Constants.ChatMessages.MaxTitleLength
                let title = newMessage.slice(0, Math.min(newMessage.length, maxTitleLength))

                let conversationCreated = await this._conversationStore?.createConversation(userId, title, currentService, selectedModel, token)
                if (conversationCreated === false) {
                    return false
                }
            }
        }

        conversationId = this._conversationStore?._selectedConversation?.id

        let streamingRequestBody = {
            query: newMessage,
            history: history,
            conversation_id: conversationId,
            user_id: this.rootStore.userStore?.currentUser?.guid
        }

        this.addMessage(
            {
                id: uuidv4(),
                message: newMessage,
                sender: 'User',
                timestamp: new Date().toISOString(),
                isUserMessage: true
            }
        )
        this._promptSuggestionsStore?.setShowPromptSuggestions(false)

        if (this.rootStore.userStore !== undefined) {

            try {
                let newToken = await this.rootStore.userStore.getAccessToken()
                if (newToken !== undefined) {
                    this.setRequestInProgress(true)

                    try {
                        let response = await chatService.askQuestionStreaming(
                            streamingRequestBody, currentService, selectedModel, newToken)
                        this.setNewChatAllowed(false)

                        if (response !== undefined) {

                            let runId = response.run_id
                            this._runId = runId
                            // console.log('runID_Request', runId)

                            let userId: string | undefined = undefined
                            if (this.rootStore.userStore.currentUser !== undefined) {
                                userId = this.rootStore.userStore.currentUser.guid
                            }

                            let receiveParameters: ReceiveParameters = {
                                serviceName: this._serviceName,
                                runId: runId,
                                token: newToken,
                                userId: userId,
                            }

                            if (this.rootStore._serverConnectionHandler !== undefined) {
                                this.rootStore._serverConnectionHandler.receive(
                                    receiveParameters
                                )
                            }

                            return true
                        }
                    } catch (error) {
                        if (axios.isAxiosError(error)) {
                            errorHandler(error)
                        } else {

                        }
                    }

                } else {
                    this.rootStore.notify(i18n.t('errorMessages.message.send'), { variant: 'error' })
                    this.setRequestInProgress(false)
                }
            } catch (error) {
                this.rootStore.notify(i18n.t('errorMessages.message.send'), { variant: 'error' })
            }
        }
        return false
    }

    onMessage(content: AskQuestionStreamingMessage) {

        if (content.tokens.length > 0) {

            this.appendCurrentStreamingTokens(content.tokens)


        }
    }

    onError(content: AskQuestionStreamingMessage) {

        console.log('onError')
        this._errorOnLastRequest = true
        this._streamingCompleted = true
        this.setCurrentMessage(this.emptyMessage)
        this.setRequestInProgress(false)
        this.removeLastUserMessage()
    }

    onCompleted() {

        this._streamingCompleted = true
        this._errorOnLastRequest = false
        this.setCurrentMessage(this.emptyMessage)

        if (this._errorOnLastRequest === false) {

            this.addMessage({
                message: this._currentStreamingTokens.join(''),
                sender: Constants.UI.BotName,
                timestamp: new Date().toISOString(),
                isUserMessage: false,
                id: this._runId
            })
            console.log('runID_Message', this._runId)
        }

        this._runId = undefined

        this.setCurrentStreamingTokens([])
        this.setRequestInProgress(false)
        this.setNewChatAllowed(true)
    }

    onFeedbackThumbUp(messageId: string): void {
        console.log(`onFeedbackThumbUp: (${this._serviceName})` + messageId)
    }

    onFeedbackThumbDown(messageId: string): void {
        console.log(`onFeedbackThumbDown: (${this._serviceName})` + messageId)
    }

    getContentFilter(): IContentFilter {

        return this.rootStore._contentFilter
    }

    resetPromptSuggestions() {

        this._promptSuggestionsStore?.samplePrompts()
        this._promptSuggestionsStore?.setShowPromptSuggestions(true)
    }

    resetModelSelection() {
        this._modelSelectionStore?.setModelSelectionVisible(true)
    }

    resetSelectedConverstation() {
        this._conversationStore?.setSelectedConversation(undefined)
    }

    clearChat() {

        this.setMessagesUI([])
        this._currentStreamingTokens = []
        this._timeoutId = undefined
        this._streamingCompleted = true

        this._messageCounter = 0
        this._currentMessage = this.emptyMessage
        this.setRequestInProgress(false)

        this._runId = undefined
    }

    clear() {
        console.log('ChatStore.clear')


        this._initialMessage = undefined

        this._serviceName = Constants.Services.Chat
        this._conversationStore?.clear()
        this._modelSelectionStore?.clear()
        this._promptSuggestionsStore?.clear()
    }
}
