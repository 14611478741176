
import { Constants } from "../../contants/Constants";
import { IFeaturePlugin } from "../../plugin/types";
import { connectionFactory } from "./ConnectionFactory";
import { IEventConnection } from "./ConnectionInterfaces";
import { StreamingCommand } from "../WebSocketClient";
import { AskQuestionStreamingMessage } from "../../types/WebletServiceTypes";


export interface ReceiveParameters {
    serviceName: string,
    runId?: string,
    token?: string,
    userId?: string,
}

export interface IServerConnectionHandler {

    set plugins(plugins: Array<IFeaturePlugin>)

    receive(receiveParameters: ReceiveParameters): Promise<void>

}

export class WebsocketConnectionHandler implements IServerConnectionHandler {

    _eventConnection: IEventConnection | null = null
    _plugins: Array<IFeaturePlugin> = []

    constructor() {
        this._eventConnection = null
    }

    set eventConnection(eventConnection: IEventConnection | null) {
        this._eventConnection = eventConnection
    }

    set plugins(plugins: Array<IFeaturePlugin>) {
        this._plugins = plugins
    }

    receive(receiveParameters: ReceiveParameters): Promise<void> {

        if (this._eventConnection === null && receiveParameters.userId !== undefined) {
            this.connectStreaming(receiveParameters.serviceName, receiveParameters.userId, receiveParameters.token)
        }
        if (this._eventConnection !== null && receiveParameters.userId !== undefined) {

            // console.log('sending command')
            let command = {
                command: Constants.WebsocketCommands.Stream,
                runId: receiveParameters.runId,
                service: Constants.Services.Chat
            }
            this._eventConnection.sendCommand(command as StreamingCommand)
        }
        return Promise.resolve();
    }

    private connectStreaming(serviceName: string, userId: string, accessToken: string = '') {

        let websocketBackendUrl = process.env.REACT_APP_WEBSOCKET_URL
        if (websocketBackendUrl !== undefined) {
            try {
                if (this._eventConnection === null) {
                    let url = `${websocketBackendUrl}/api/streaming/${userId}?token=${accessToken}`

                    this._eventConnection = connectionFactory.createConnection('ws', url)

                }
                if (this._eventConnection !== null) {
                    this._eventConnection.connect()

                    // connect plugins to websocket
                    if (this._eventConnection !== null) {
                        // console.log('binding message function')
                        this._eventConnection.onMessage = this.onMessage.bind(this)
                    }
                }
            } catch (error) {
                throw error
            }
        }
    }

    onMessage(runId: string, message: string) {
        // console.log('onMessage')
        // console.log(message)

        this._plugins.forEach((plugin) => {
            plugin.onMessage(message)
        })
    }
}

export class SSEConnectionHandler implements IServerConnectionHandler {

    _eventConnection: IEventConnection | null = null
    _plugins: Array<IFeaturePlugin> = []

    constructor() {
        this._eventConnection = null
    }

    set plugins(plugins: Array<IFeaturePlugin>) {
        this._plugins = plugins
    }

    receive(receiveParameters: ReceiveParameters): Promise<void> {
        let serviceName = receiveParameters.serviceName
        let runId = receiveParameters.runId
        if (runId !== undefined) {

            let backendUrl = process.env.REACT_APP_BACKEND_URL
            if (backendUrl !== undefined) {
                try {

                    let url = `${backendUrl}/api/chat/sse/${receiveParameters.runId}?service=${serviceName}&token=${receiveParameters.token}`
                    // const config = {
                    //     headers: {
                    //         Authorization: `Bearer ${token}`
                    //     },
                    //     withCredentials: true
                    // };
                    //document.cookie = `token=${token}; path=/;`
                    this._eventConnection = connectionFactory.createConnection('sse', url)

                    if (this._eventConnection !== null) {
                        this._eventConnection.connect()
                        if (this._eventConnection !== null) {
                            this._eventConnection.onMessage = this.onMessage.bind(this, runId)
                        }
                    }
                } catch (error) {
                    throw error
                }
            }
        }
        return Promise.resolve(undefined);
    }

    onMessage(runId: string, message: string) {
        // console.log('IncommingMessage: onMessage')
        // console.log(message)

        this._plugins.forEach((plugin) => {
            // console.log('forwarding to plugin')
            // console.log(message)
            plugin.onMessage(message)
        })

        const receivedData: string = message;
        let object = JSON.parse(receivedData) as AskQuestionStreamingMessage
        if (object.status == 'completed') {
            this._eventConnection?.disconnect()
            this._eventConnection = null
        }

    }
}