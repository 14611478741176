import React, { useState } from 'react';
import { Box, IconButton, TextField, useTheme } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import { keyframes } from "@emotion/react";
import { observer } from 'mobx-react';
import { QuickActions } from '../../../types/ChatTypes';

import InputAdornment from '@mui/material/InputAdornment';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface MessageInputProps {
    onSendMessage: (message: string) => void;
    disabled?: boolean;
    textfieldTitle?: string
    initialMessage?: string
    setInitialMessage?: (message: string, reset: boolean) => void
    quickActions?: QuickActions[]
    requestInProgress?: boolean
}

const MessageInput: React.FC<MessageInputProps> = observer(({
    initialMessage,
    onSendMessage,
    disabled,
    textfieldTitle = "Ask a question ...",
    setInitialMessage,
    quickActions,
    requestInProgress }) => {

    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleQuickActionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        //setOpen(true);
    };

    const handleQuickActionsClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (setInitialMessage) {
            setInitialMessage(event.target.value, false);
        }
    };

    const handleSendMessage = () => {
        if (initialMessage) {
            let m = initialMessage.trim()
            onSendMessage(m);
            if (setInitialMessage) {
                setInitialMessage('', true);
            }
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (event.key === 'Enter' && !event.shiftKey) {
            handleSendMessage();
        }
    };

    const pulsateAnimation = keyframes`
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    `;

    // console.log("requestInProgress", requestInProgress)

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                padding: '16px',
                borderTop: '1px solid',
                borderColor: 'divider'
            }}
        >
            <TextField
                fullWidth
                multiline
                maxRows={4}
                value={initialMessage || ''}
                disabled={disabled}
                onChange={handleInputChange}
                onKeyUp={handleKeyPress}
                onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); } }}
                placeholder={textfieldTitle}
                InputProps={{
                    startAdornment: (quickActions !== undefined && quickActions.length) ? (
                        <InputAdornment position="start">
                            <IconButton onClick={handleQuickActionsClick}>
                                <MoreVertIcon />
                            </IconButton>
                        </InputAdornment>
                    ) : (null),
                }}
                sx={{ ml: 1 }}
            />
            <IconButton onClick={handleSendMessage} disabled={initialMessage === undefined} color="primary">
                {requestInProgress && (
                    <SendIcon sx={{ animation: `${pulsateAnimation} 1.5s infinite ${theme.transitions.easing.easeInOut}` }} />
                )}

                {!requestInProgress && (
                    <SendIcon />
                )}

            </IconButton>
            {quickActions !== undefined && quickActions.length &&
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleQuickActionsClose}>
                    {quickActions.map((quickAction: QuickActions, index: number) => {
                        return (<MenuItem key={index} onClick={() => {
                            if (quickAction.action) {
                                quickAction.action()
                            }

                            handleQuickActionsClose()
                        }}>{quickAction.title}</MenuItem>
                        )
                    })}

                </Menu>
            }
        </Box>
    );
});

export {
    MessageInput,
    MessageInputProps
} 
