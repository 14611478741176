
import { action, makeObservable, observable } from 'mobx';
import { HandleMesssageResult, IHandleSendMessage } from '../../../stores/BaseStreamingChatStore';
import { RootStore } from '../../../stores/RootStore';
import { chatService } from '../../../services/ChatService';
import { documentService } from '../../../services/DocumentService';
import { Constants } from '../../../contants/Constants';

import { ChatMessageProps } from '../../../types/ChatTypes';

import { ReceiveParameters } from '../../../utils/serverside/ServerConnectionHandler';
import i18n from '../../../i18n';
import { v4 as uuidv4 } from 'uuid';
import { ContentStatus, IContentFilter, IContentFilterResult } from '../../../AIShield/AIShieldTypes';
import { DocumentProcessingStatusTracker, ProcessedStatus } from '../utils/DocumentProcessingState';
import { isDocumentAccessible } from '../utils/DocumentUtils';
import { ThreeSixty } from '@mui/icons-material';
import { IConversationHandler, IFeaturePluginStore } from '../../../plugin/types';
import { StreamingChatStore } from '../../../stores/StreamingChatStore';
import { Conversation } from '../../../types/types_api';
import axios from 'axios';
import { errorHandler } from '../../../services/ErrorHandler';


export class DocumentChatStore implements IFeaturePluginStore {

    _rootStore: RootStore | undefined = undefined;

    _uploadInProgress: boolean = false
    _uploadFinished: boolean = false

    _documentsPresent: boolean = false
    _tmpInitialMessage: string = ''

    allowedExtensions = ['pdf'];

    _openClearDialog: boolean = false

    _openDeleteDocumentDialog: boolean = false

    _inplaceMenuVisible: boolean = true

    _openDialogDocumentFromUrl: boolean = false
    _openDialogDocumentFromFile: boolean = false

    _documentProcessingVisible: boolean = false
    _processintStatusTracker: DocumentProcessingStatusTracker | undefined = undefined
    _documentProcessingPercentage: number = 0

    _documentPreProcessingVisible: boolean = false
    _openContentWarningDialog: boolean = false
    _streamingChatStore: StreamingChatStore

    constructor(rootStore: RootStore) {

        this._rootStore = rootStore;
        this._uploadInProgress = false
        this._uploadFinished = false

        this._documentsPresent = false

        makeObservable(this, {
            _uploadInProgress: observable,
            _uploadFinished: observable,
            setUploadInProgress: action,
            setUploadFinished: action,

            // actions
            _openClearDialog: observable,
            setOpenClearDialog: action,

            // actions
            _openDeleteDocumentDialog: observable,
            setOpenDialogDeleteDocument: action,

            // inplaceme menu 
            _inplaceMenuVisible: observable,
            setInplaceMenuVisible: action,

            // inplaceme menu 
            _documentProcessingVisible: observable,
            setDocumentProcessingVisible: action,

            _documentProcessingPercentage: observable,
            setDocumentProcessingPercentage: action,

            // upload
            _openDialogDocumentFromUrl: observable,
            setOpenDialogDocumentFromUrl: action,

            _openDialogDocumentFromFile: observable,
            setOpenDialogDocumentFromFile: action,

            _documentPreProcessingVisible: observable,
            setDocumentPreProcessingVisible: action,

            _openContentWarningDialog: observable,
            setOpenContentWarningDialog: action
        });


        this._streamingChatStore = new StreamingChatStore(rootStore, Constants.Services.DocChat)
        this._streamingChatStore.setNewChatAllowed(false)

        if (this._streamingChatStore.conversationStore !== undefined) {
            this._streamingChatStore.conversationStore.conversationSelectedCallback = this.onConversationSelected.bind(this)
            this._streamingChatStore.conversationStore.conversationDeletedCallback = this.onConversationDeleted.bind(this)
        }

        this.setInplaceMenuVisible(false)
        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(true)
    }

    get documentsPresent(): boolean {
        return this._documentsPresent
    }

    setDocumentsPresent(documentsPresent: boolean): void {
        this._documentsPresent = documentsPresent
    }

    promptSelectionCallback(initialMessage: string) {
        if (this.documentsPresent === true) {
            this._tmpInitialMessage = initialMessage
            this._streamingChatStore.setInitialMessage(initialMessage)
        } else {
            let selection = 'chat-with-document-from-url'
            this._streamingChatStore.setInitialMessage(initialMessage)
            this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
            this.handleInplaceMenuSelection(selection)

        }
    }

    setOpenClearDialog(openClearDialog: boolean) {
        this._openClearDialog = openClearDialog
    }

    get openClearDialog(): boolean {
        return this._openClearDialog
    }

    setOpenContentWarningDialog(openContentWarningDialog: boolean) {
        this._openContentWarningDialog = openContentWarningDialog
    }

    get openContentWarningDialog() {
        return this._openContentWarningDialog
    }

    quickActionClearChatHistory() {
        this.setOpenClearDialog(true);
    };

    quickActionUploadDocument() {
        let selection = 'chat-with-document-from-url'
        this._streamingChatStore.setInitialMessage('')

        this.handleInplaceMenuSelection(selection)
        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(true)
    }

    setOpenDialogDeleteDocument(openDialogDeleteDocument: boolean) {
        // console.log('setOpenDialogDeleteDocument', openDialogDeleteDocument)
        this._openDeleteDocumentDialog = openDialogDeleteDocument
    }

    get openDialogDeleteDocument() {
        return this._openDeleteDocumentDialog
    }

    get documentProcessingVisible(): boolean {
        return this._documentProcessingVisible
    }

    setDocumentProcessingVisible(documentProcessingVisible: boolean) {
        this._documentProcessingVisible = documentProcessingVisible
    }

    get documentProcessingPercentage(): number {
        return this._documentProcessingPercentage
    }

    setDocumentPreProcessingVisible(documentPreProcessingVisible: boolean) {
        this._documentPreProcessingVisible = documentPreProcessingVisible
    }

    get documentPreProcessingVisible(): boolean {
        return this._documentPreProcessingVisible
    }

    setDocumentProcessingPercentage(documentProcessingPercentage: number) {
        this._documentProcessingPercentage = documentProcessingPercentage
    }

    quickActionDeleteDocument() {
        this.setOpenDialogDeleteDocument(true);
    }

    handleClearChatYes() {
        this.setOpenClearDialog(false);
        this.onNewChat()
        this._streamingChatStore.setInputFieldDisabled(false)
        this.setInplaceMenuVisible(false)
        this._streamingChatStore.setNewChatAllowed(false)
    };

    handleClearChatNo() {
        this.setOpenClearDialog(false);
    };

    async handleDeleteDocumentYes() {

        this.setOpenDialogDeleteDocument(false);
        this.deleteCurrentDocument()
        //this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
        this._streamingChatStore.setNewChatAllowed(false)

        this.onNewChat()
        //this.setInplaceMenuVisible(true)
        this.setDocumentsPresent(false)

        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(true)

        this._streamingChatStore.setInputFieldDisabled(true)
        this._streamingChatStore.setNewChatAllowed(false)
    }

    handleDeleteDocumentNo() {
        this.setOpenDialogDeleteDocument(false);
    }

    // inplace menu
    get inplaceMenuVisible() {
        return this._inplaceMenuVisible
    }

    setInplaceMenuVisible(inplaceMenuVisible: boolean) {
        this._inplaceMenuVisible = inplaceMenuVisible
    }

    // document from url
    get openDialogDocumentFromUrl() {
        return this._openDialogDocumentFromUrl
    }

    setOpenDialogDocumentFromUrl(openDialogDocumentFromUrl: boolean) {
        this._openDialogDocumentFromUrl = openDialogDocumentFromUrl
    }

    setOpenDialogDocumentFromFile(openDialogDocumentFromFile: boolean) {
        this._openDialogDocumentFromFile = openDialogDocumentFromFile
    }

    async openDialogDocumentFromUrlSubmit(url: string) {
        this.setOpenDialogDocumentFromUrl(false)
        try {
            let isAccessible = await Promise.resolve(true)
            //let isAccessible = await isDocumentAccessible(url)
            if (isAccessible === false) {
                this._rootStore?.notify(i18n.t('feature.docChat.errorMessages.document.urlNotAccessible'), { variant: 'error' })
                return
            } else {
                this.handleUploadFromLink(url)
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                errorHandler(error)

            } else {
                console.log(error)
            }
        }
    }

    openDialogDocumentFromUrlCancel() {
        this.setOpenDialogDocumentFromUrl(false)
    }

    handleInplaceMenuSelection(selection: string) {
        if (selection === 'chat-with-document-from-url') {
            this.setOpenDialogDocumentFromUrl(true)
        }
    }



    //upload
    setUploadInProgress(uploadInProgress: boolean) {
        this._uploadInProgress = uploadInProgress
    }

    get uploadInProgress() {
        return this._uploadInProgress
    }

    setUploadFinished(uploadFinished: boolean) {
        this._uploadFinished = uploadFinished
    }

    get uploadFinished() {
        return this._uploadFinished
    }

    handleStreamingError() {
        if (this._streamingChatStore.getHistory.length > 0) {
            this._streamingChatStore?.setNewChatAllowed(true)
        } else {
            this._streamingChatStore?.setNewChatAllowed(false)
        }
    }

    async checkContent(newMessage: string): Promise<HandleMesssageResult> {
        return this._streamingChatStore?.checkContent(newMessage)
    }

    async sendMessage(newMessage: string): Promise<boolean> {
        return this._streamingChatStore?.sendMessage(newMessage)
    }

    containsCriticalCategories(messageResult: HandleMesssageResult): boolean {
        if (this._rootStore !== undefined) {
            if (this._rootStore._contentFilter !== undefined) {
                return this._rootStore?._contentFilter.containsCriticalCategories(messageResult)
            }
        }
        return false
    }

    async initialize() {
        console.log('Initializing DocumentChat Store')

        let userId = this._rootStore?.userStore?.currentUser?.guid
        let token = await this._rootStore?.userStore?.getAccessToken();

        if (userId !== undefined && token !== undefined) {
            // console.log(token)

            let serviceName = Constants.Services.DocChat
            if (this._streamingChatStore._conversationStore !== undefined) {
                await this._streamingChatStore._conversationStore.retrieveConversationsForUser(userId, serviceName, token)
            }

            if (this._streamingChatStore._promptSuggestionsStore !== undefined) {
                let currentLanguage = i18n.language
                this._streamingChatStore._promptSuggestionsStore.setShowPromptSuggestions(true)
                await this._streamingChatStore._promptSuggestionsStore.retrievePromptSuggestions(serviceName, currentLanguage)

            }
        }
        this._rootStore?.fireUpdateUI()
    }

    async onFeedbackThumbUp(messageId: string) {

        let token = await this._rootStore?.userStore?.getAccessToken()
        if (this._streamingChatStore._conversationStore) {
            this._streamingChatStore._conversationStore.setFeedback(messageId, true, token)
        }
    }

    async onFeedbackThumbDown(messageId: string) {
        //chatStore.onFeedbackThumbUp(messageId)
        let token = await this._rootStore?.userStore?.getAccessToken()
        if (this._streamingChatStore._conversationStore) {
            this._streamingChatStore._conversationStore.setFeedback(messageId, false, token)
        }
    }

    async handleUploadPDF(file: File, simulate: boolean = false) {
        // Simulate the file upload
        this.setUploadInProgress(true);

        if (this._rootStore?.userStore !== undefined) {
            try {
                let token = await this._rootStore.userStore.getAccessToken()
                let userId = this._rootStore.userStore.currentUser?.guid
                if (token !== undefined && userId !== undefined) {
                    // let response = await webletService.addDataFromPDF({pdf: file, split: true}, token);
                    let response = await documentService.addDataFromPDF(file, userId, token);
                    console.log(response)
                    if (response.id !== undefined) {

                        this._processintStatusTracker = new DocumentProcessingStatusTracker()
                        this._processintStatusTracker.connect(response.id, token, this.onDocumentProcessingUpdate.bind(this))
                        //let totalChunks = 20
                        //this._processintStatusTracker.simulateMessage(response.id, token, this.onDocumentProcessingUpdate.bind(this), totalChunks)
                        //this._rootStore.notify(`Successfully uploaded: ${documentLink}`, { variant: 'success' })

                        this._rootStore?.notify(i18n.t('feature.docChat.userMessages.documentUpload.success'), { variant: 'success' })
                        // init progressbar backgroup

                        this.setDocumentPreProcessingVisible(true)
                        this.setDocumentProcessingPercentage(0)

                        this.setInplaceMenuVisible(false)
                        this.setUploadFinished(true)
                        this._streamingChatStore.setNewChatAllowed(false)
                        // this.resetPromptSuggestions()
                    }
                    else {
                        // this._rootStore.notify(`Error uploading: ${documentLink}`, { variant: 'error' })
                        this._rootStore.notify(i18n.t(`feature.docChat.errorMessages.document.upload`, {
                            documentLink: file.name
                        }), { variant: 'error' })
                    }
                } else {
                    this._rootStore.notify('Error sending message. Login Again.', { variant: 'error' })
                    // TODO: could this case happen?
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    errorHandler(error)
                    this._rootStore.notify(i18n.t(`feature.docChat.errorMessages.document.upload`, {
                        documentLink: file.name
                    }), { variant: 'error' })
                } else {
                    console.log(error)
                }
            }
        }
        this.setUploadInProgress(false);
    };

    async handleUploadFromLink(documentLink: string) {
        // Simulate the file upload
        console.log(`Received file: ${documentLink}`);
        this.setUploadInProgress(true);

        if (this._rootStore?.userStore !== undefined) {
            try {
                let token = await this._rootStore.userStore.getAccessToken()
                let userId = this._rootStore.userStore.currentUser?.guid
                if (token !== undefined && userId !== undefined) {
                    // let response = await webletService.addDataFromPDF({pdf: file, split: true}, token);
                    let response = await documentService.addDataFromLink(userId, documentLink, token);
                    console.log(response)
                    if (response.id !== undefined) {

                        this._processintStatusTracker = new DocumentProcessingStatusTracker()
                        this._processintStatusTracker.connect(response.id, token, this.onDocumentProcessingUpdate.bind(this))
                        //let totalChunks = 20
                        //this._processintStatusTracker.simulateMessage(response.id, token, this.onDocumentProcessingUpdate.bind(this), totalChunks)
                        //this._rootStore.notify(`Successfully uploaded: ${documentLink}`, { variant: 'success' })

                        this._rootStore?.notify(i18n.t('feature.docChat.userMessages.documentUpload.success'), { variant: 'success' })
                        // init progressbar backgroup

                        this.setDocumentPreProcessingVisible(true)
                        this.setDocumentProcessingPercentage(0)

                        this.setInplaceMenuVisible(false)
                        this.setUploadFinished(true)
                        this._streamingChatStore.setNewChatAllowed(false)
                        // this.resetPromptSuggestions()
                    }
                    else {
                        // this._rootStore.notify(`Error uploading: ${documentLink}`, { variant: 'error' })
                        this._rootStore.notify(i18n.t(`feature.docChat.errorMessages.document.upload`, {
                            documentLink: documentLink
                        }), { variant: 'error' })
                    }
                } else {
                    this._rootStore.notify('Error sending message. Login Again.', { variant: 'error' })
                    // TODO: could this case happen?
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    errorHandler(error)
                    this._rootStore.notify(i18n.t(`feature.docChat.errorMessages.document.upload`, {
                        documentLink: documentLink
                    }), { variant: 'error' })
                } else {
                    console.log(error)
                }
            }
        }
        this.setUploadInProgress(false);
    };

    onDocumentProcessingUpdate(id: string, message: string) {
        console.log('IncommingMessage: onMessage')
        console.log(message)

        const receivedData: string = message;
        let object = JSON.parse(receivedData) as ProcessedStatus

        if (object.status === 'processing') {
            if (object.processed > 0) {
                this.setDocumentPreProcessingVisible(false)
                this.setDocumentProcessingVisible(true)
            }
            let documentProcessingPercentage = Math.floor(object.processed / object.num_chunks * 100)
            console.log('documentProcessingPercentage', documentProcessingPercentage)
            this.setDocumentProcessingPercentage(Math.floor(object.processed / object.num_chunks * 100))
        }

        if (object.status === 'completed') {

            this._rootStore?.notify(i18n.t('feature.docChat.userMessages.documentProcessingCompleted'), { variant: 'success' })

            this.setDocumentProcessingVisible(false)
            this.setDocumentPreProcessingVisible(false)
            this.setInplaceMenuVisible(false)

            this.setUploadFinished(true)

            this.setDocumentProcessingPercentage(0)

            // enable input field
            this._streamingChatStore.setInputFieldDisabled(false)
            // because we dont have a conversation yet
            this._streamingChatStore.setNewChatAllowed(false)
            this.setDocumentsPresent(true)
        }
    }

    async deleteCurrentDocument() {
        try {
            let token = await this._rootStore?.userStore?.getAccessToken()
            let userId = this._rootStore?.userStore?.currentUser?.guid;
            // console.log('Delete Document', userId, token)
            if (token !== undefined && userId !== undefined) {
                let result = documentService.deleteDocument(userId, token)
                if (result !== undefined) {
                    this._rootStore?.notify(i18n.t('feature.docChat.userMessages.documentDelete.success'), { variant: 'success' })
                }
            }
        } catch (error) {
            console.log(error)
            this._rootStore?.notify(i18n.t('errorMessages.document.delete'), { variant: 'error' })
        }
    }

    onConversationSelected(conversationId: string, chatMessagePropsArray: ChatMessageProps[]): void {

        this._streamingChatStore.setMessagesUI(chatMessagePropsArray)
        // update the model selection
        let conversationModel = this._streamingChatStore?._conversationStore?._selectedConversation?.model
        if (conversationModel !== undefined) {
            this._streamingChatStore._modelSelectionStore?.initializeFromModelId(conversationModel)
        }
        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
        if (this._streamingChatStore.getHistory().length > 0) {
            this._streamingChatStore?.setNewChatAllowed(true)
        }
    }

    onConversationDeleted(conversationId: string): void {

        if (conversationId === this._streamingChatStore?._conversationStore?._selectedConversation?.id) {
            this.onNewChat()
            this._streamingChatStore.clearChat()
            this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
            this._streamingChatStore._modelSelectionStore?.setModelSelectionVisible(true)
            this._streamingChatStore.setNewChatAllowed(false)
        }
    }

    onNewChat() {
        this._streamingChatStore.clearChat()


        this._streamingChatStore.resetModelSelection()
        this._streamingChatStore.resetSelectedConverstation()
    }

    clear() {
        console.log('DocumentChatStore.clear')
        this.deleteCurrentDocument()

        this._streamingChatStore.clearChat()
        this.setInplaceMenuVisible(true)
        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
        this._streamingChatStore._modelSelectionStore?.setModelSelectionVisible(true)

        this.setUploadInProgress(false)
        this.setUploadFinished(false)
        this._streamingChatStore.setInputFieldDisabled(true)

        this._streamingChatStore._conversationStore?.clear()
        this._streamingChatStore._modelSelectionStore?.clear()
        this._streamingChatStore._promptSuggestionsStore?.clear()
    }
}
