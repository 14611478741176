
import * as React from 'react';
import { Button, Box, Snackbar, Tooltip, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

interface UploadButtonProps {
    simulate: boolean;
    allowedFileExtensions: string[];
    onUpload: (file: File, simulate: boolean) => void;
}

const UploadButton: React.FC<UploadButtonProps> = ({ allowedFileExtensions, onUpload, simulate }) => {
    const { t, i18n } = useTranslation();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [alert, setAlert] = React.useState<{ open: boolean, message: string }>({ open: false, message: '' });

    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        if (!file) {
            return;
        }
        const extension = file.name.split('.').pop();
        if (!extension || !allowedFileExtensions.includes(extension)) {
            let allowedFileExtensionsString = allowedFileExtensions.join(', ');
            setAlert({ open: true, message: t("feature.docChat.buttonUpload.error", { 'allowedExtensions': allowedFileExtensionsString }) });
        } else {

            onUpload(file, simulate);
        }
    };

    return (
        <Box component="span">
            <input
                ref={inputRef}
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            {/* <Box sx={{ p: 0, m: 1, height: "100%", display: 'flex', flexDirection: 'column', }}>
                <Typography fontSize={14} textAlign={'left'} sx={{ m: 0, fontWeight: 'bold' }}>{t('feature.docChat.documentIngestionMenus.chatWithDocumentUpload.title')}</Typography>
                <Typography fontSize={14} textAlign={'left'} sx={{}}>{t('feature.docChat.documentIngestionMenus.chatWithDocumentUpload.description')}</Typography>
            </Box> */}
            {/* <Tooltip title={t("feature.docChat.buttonUpload.toolTipAllowedExtensions", { 'allowedExtensions': allowedFileExtensions })}> */}
            <Button onClick={handleClick} style={{ backgroundColor: 'transparent' }}>
                <Box sx={{ p: 0, m: 0, height: "100%", display: 'flex', flexDirection: 'column' }}>
                    <Typography fontSize={14} textAlign={'left'} sx={{ m: 0, fontWeight: 'bold', color: 'black' }}>{t('feature.docChat.documentIngestionMenus.chatWithDocumentUpload.title')}</Typography>
                    <Typography fontSize={14} textAlign={'left'} sx={{ color: 'black' }}>{t('feature.docChat.documentIngestionMenus.chatWithDocumentUpload.description')}</Typography>
                </Box>
            </Button>
            {/* </Tooltip> */}
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alert.open} autoHideDuration={6000} onClose={() => setAlert({ open: false, message: '' })}>
                <Alert onClose={() => setAlert({ open: false, message: '' })} severity="error" sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default observer(UploadButton)
