
import { action, makeAutoObservable, makeObservable, observable } from 'mobx';
import { HandleMesssageResult, IHandleSendMessage } from '../../../stores/BaseStreamingChatStore';
import { RootStore } from '../../../stores/RootStore';

import { chatService } from '../../../services/ChatService';
import { Constants } from '../../../contants/Constants';
import { IConversationHandler, IFeaturePluginStore } from '../../../plugin/types';
import { ReceiveParameters } from '../../../utils/serverside/ServerConnectionHandler';

import i18n from '../../../i18n';
import { v4 as uuidv4 } from 'uuid';
import { ContentStatus, IContentFilter, IContentFilterResult } from '../../../AIShield/AIShieldTypes';
import { StreamingChatStore } from '../../../stores/StreamingChatStore';
import { ChatMessageProps } from '../../../types/ChatTypes';


export class ChatStore implements IFeaturePluginStore {

    _rootStore: RootStore | undefined = undefined;

    @observable
    _openClearDialog: boolean = false

    @observable
    _openContentWarningDialog: boolean = false

    _streamingChatStore: StreamingChatStore

    constructor(rootStore: RootStore) {

        makeAutoObservable(this)
        this._openClearDialog = false
        this._rootStore = rootStore
        this._openContentWarningDialog = false

        this._streamingChatStore = new StreamingChatStore(rootStore, Constants.Services.Chat)
        this._streamingChatStore.setNewChatAllowed(false)
        if (this._streamingChatStore.conversationStore !== undefined) {
            this._streamingChatStore.conversationStore.conversationSelectedCallback = this.onConversationSelected.bind(this)
            this._streamingChatStore.conversationStore.conversationDeletedCallback = this.onConversationDeleted.bind(this)
        }
    }

    @action
    setOpenClearDialog(openClearDialog: boolean) {
        this._openClearDialog = openClearDialog
    }

    get openClearDialog() {
        return this._openClearDialog
    }

    @action
    setOpenContentWarningDialog(openContentWarningDialog: boolean) {
        this._openContentWarningDialog = openContentWarningDialog
    }

    get openContentWarningDialog() {
        return this._openContentWarningDialog
    }

    quickActionClearChatHistory() {
        this.setOpenClearDialog(true);
    };

    handleClearChatYes() {
        this.setOpenClearDialog(false);
        this.onNewChat()
    };

    handleClearChatNo() {
        this.setOpenClearDialog(false);
    };

    handleStreamingError() {
        if (this._streamingChatStore.getHistory.length > 0) {
            this._streamingChatStore?.setNewChatAllowed(true)
        } else {
            this._streamingChatStore?.setNewChatAllowed(false)
        }
    }

    async checkContent(newMessage: string): Promise<HandleMesssageResult> {
        return this._streamingChatStore?.checkContent(newMessage)
    }

    async sendMessage(newMessage: string): Promise<boolean> {
        return this._streamingChatStore?.sendMessage(newMessage)
    }

    containsCriticalCategories(messageResult: HandleMesssageResult): boolean {
        if (this._rootStore !== undefined) {
            if (this._rootStore._contentFilter !== undefined) {
                return this._rootStore?._contentFilter.containsCriticalCategories(messageResult)
            }
        }
        return false
    }

    async initialize() {
        console.log('Initializing Chat Store')

        let userId = this._rootStore?.userStore?.currentUser?.guid
        if (userId !== undefined) {

            let token = await this._rootStore?.userStore?.getAccessToken();
            let serviceName = Constants.Services.Chat
            if (this._streamingChatStore?._conversationStore !== undefined) {
                await this._streamingChatStore?._conversationStore.retrieveConversationsForUser(userId, serviceName, token)
            }
            if (this._streamingChatStore?._promptSuggestionsStore !== undefined) {
                let currentLanguage = i18n.language
                await this._streamingChatStore?._promptSuggestionsStore.retrievePromptSuggestions(serviceName, currentLanguage)
            }
            this._rootStore?.fireUpdateUI()
        }
    }

    onNewChat() {
        this._streamingChatStore?.clearChat()
        this._streamingChatStore?.resetPromptSuggestions()
        this._streamingChatStore?.setInitialMessage(undefined)
        this._streamingChatStore?.modelSelectionStore?.setModelSelectionVisible(true)
        this._streamingChatStore?.resetSelectedConverstation()
    }

    onConversationSelected(conversationId: string, chatMessagePropsArray: ChatMessageProps[]): void {
        this._streamingChatStore.setMessagesUI(chatMessagePropsArray)
        // update the model selection
        let conversationModel = this._streamingChatStore?._conversationStore?._selectedConversation?.model
        if (conversationModel !== undefined) {
            this._streamingChatStore._modelSelectionStore?.initializeFromModelId(conversationModel)
        }
        this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
        if (this._streamingChatStore.getHistory().length > 0) {
            this._streamingChatStore?.setNewChatAllowed(true)
        }
    }

    onConversationDeleted(conversationId: string): void {

        if (conversationId === this._streamingChatStore?._conversationStore?._selectedConversation?.id) {
            this.onNewChat()
            this._streamingChatStore.clearChat()
            this._streamingChatStore._promptSuggestionsStore?.setShowPromptSuggestions(false)
            this._streamingChatStore._modelSelectionStore?.setModelSelectionVisible(true)
            this._streamingChatStore.setNewChatAllowed(false)
        }
    }

    clear() {
        console.log('ChatStore.clear')
        this._streamingChatStore?.clearChat()
        this.setOpenClearDialog(false)
        this._streamingChatStore?.clear()
    }
}
