import React, { useRef, useState } from 'react';
import { Box, ButtonBase, Grid, Paper, Tooltip, Typography } from '@mui/material';

import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { UploadButton } from '../../../../components/UploadButton';

interface InplaceMenuItem {
    title: string
    description: string
    menuId: string
    disabled: boolean
}

interface DocumentChatInplaceMenuProps {
    handleInplaceMenuSelectionSelection: (menu: string) => void
    handleFileUpload: (file: File, simulate: boolean) => void
}

const DocumentChatInplaceMenuItems: React.FC<DocumentChatInplaceMenuProps> = observer(({
    handleInplaceMenuSelectionSelection,
    handleFileUpload
}) => {
    const { t, i18n } = useTranslation('');

    let inplaceMenuItems = [
        {
            title: t('feature.docChat.documentIngestionMenus.chatWithDocumentFromUrl.title'),
            description: t('feature.docChat.documentIngestionMenus.chatWithDocumentFromUrl.description'),
            menuId: 'chat-with-document-from-url',
            disabled: false
        },
        {
            title: t('feature.docChat.documentIngestionMenus.chatWithDocumentUpload.title'),
            description: t('feature.docChat.documentIngestionMenus.chatWithDocumentUpload.description'),
            menuId: 'chat-with-document-upload',
            disabled: false
        }
    ] as InplaceMenuItem[];
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',

                alignItems: 'center',
                padding: '16px'
            }}
        >
            <Grid container spacing={2} justifyContent={'center'}>
                {
                    inplaceMenuItems.map((inplaceMenuItem: InplaceMenuItem, index: number) => {
                        return (
                            < Grid key={`prompt-index- ${index}`} item xs={12} md={6}>
                                {inplaceMenuItem.menuId === 'chat-with-document-from-url' ?
                                    <ButtonBase onClick={() => { handleInplaceMenuSelectionSelection(inplaceMenuItem.menuId) }}
                                        disabled={inplaceMenuItem.disabled}
                                        sx={{ m: 0, p: 0, width: '100%', height: '100%' }}>
                                        <Paper
                                            elevation={3}
                                            sx={{
                                                p: 0, m: 1, borderRadius: 5, height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                                                color: !inplaceMenuItem.disabled ? 'text.primary' : 'text.disabled',
                                                transition: 'transform 0.3s',
                                                '&:hover': {
                                                    transform: 'scale(1.02)',
                                                }
                                            }}>
                                            <Box
                                                sx={{
                                                    p: 0, m: 1, height: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
                                                }}>
                                                <Box sx={{ p: 0, m: 1, height: "100%", display: 'flex', flexDirection: 'column' }}>
                                                    <Typography fontSize={14} textAlign={'left'} sx={{ m: 0, fontWeight: 'bold' }}>{inplaceMenuItem.title}</Typography>
                                                    <Typography fontSize={14} textAlign={'left'} sx={{}}>{inplaceMenuItem.description}</Typography>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </ButtonBase> :
                                    <ButtonBase onClick={() => { handleInplaceMenuSelectionSelection(inplaceMenuItem.menuId) }}
                                        disabled={inplaceMenuItem.disabled}
                                        sx={{ m: 0, p: 0, width: '100%', height: '100%' }}>

                                        <Paper
                                            elevation={3}
                                            sx={{
                                                p: 0, m: 1, borderRadius: 5, height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center',
                                                color: !inplaceMenuItem.disabled ? 'text.primary' : 'text.disabled',
                                                transition: 'transform 0.3s',
                                                '&:hover': {
                                                    transform: 'scale(1.02)',
                                                }
                                            }}>
                                            <Box
                                                sx={{
                                                    p: 0, m: 0, height: "100%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
                                                }}>
                                                {/* <Box sx={{ p: 0, m: 1, height: "100%", display: 'flex', flexDirection: 'column', }}>
                                                    <Typography fontSize={14} textAlign={'left'} sx={{ m: 0, fontWeight: 'bold' }}>{inplaceMenuItem.title}</Typography>
                                                    <Typography fontSize={14} textAlign={'left'} sx={{}}>{inplaceMenuItem.description}</Typography>
                                                </Box> */}
                                                <UploadButton onUpload={handleFileUpload} allowedFileExtensions={["pdf"]} simulate={false} />
                                            </Box>
                                        </Paper>

                                    </ButtonBase>
                                }
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box >
    );
});

export {
    InplaceMenuItem,
    DocumentChatInplaceMenuItems
} 
