import axios, { AxiosError, AxiosInstance } from 'axios';
import {
  AnswerRequestModel,
  AskQuestionResponseModel,
  AskQuestionStreamingResponseModel
} from '../types/WebletServiceTypes';
import { Constants } from '../contants/Constants';


class ChatGFTService {

  private client: AxiosInstance;

  constructor(baseURL: string, service: string = '') {

    this.client = axios.create({ baseURL: baseURL + `/api` });
  }

  async askQuestion(data: AnswerRequestModel, token?: string): Promise<AskQuestionResponseModel | undefined> {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
      withCredentials: true
    };
    try {

      const response = await this.client.post<AskQuestionResponseModel>('/answer', data, config);
      return response.data;
    } catch (error: any) {
      throw error
    }
  }

  async askQuestionStreaming(data: AnswerRequestModel, serviceName: string, model_id: string, token?: string):
    Promise<AskQuestionStreamingResponseModel | undefined> {

    console.log('askQuestionStreaming', data, serviceName, model_id)
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
      withCredentials: true,
      params: {
        service: serviceName,
        model: model_id
      }
    };

    console.log('askQuestionStreaming', data, config, {
      service: serviceName,
      model: model_id
    })

    try {
      const response = await this.client.post<AskQuestionStreamingResponseModel>(Constants.Endpoints.Chat, data, config);
      return response.data;
    } catch (error: any) {
      throw error
    }
  }
}

export const chatService = new ChatGFTService(process.env.REACT_APP_BACKEND_URL ?? "http://localhost:3001", process.env.REACT_APP_SERVICE ?? "cognitive");
