

export class Constants {
    public static readonly System = class {
        public static readonly WIDGET_VERSION = "v1.0.0"
    }

    public static readonly Routes = class {
        public static readonly Home = '/'
        public static readonly Login = '/login'
        public static readonly Chat = '/chat'
        public static readonly DocumentChat = '/documentchat'
        public static readonly Summaries = '/summaries'
    }

    public static readonly UI = class {
        public static readonly BotName = 'Bot'
        public static readonly AvatarUrlAI = process.env.REACT_APP_AVATAR_URL_AI
        public static readonly AvatarUrlUser = ''
    }

    public static readonly Endpoints = class {
        public static readonly Login = '/api/auth/token'
        public static readonly Chat = '/chat/stream'
        public static readonly DocChat = '/chat/stream'
        public static readonly DocCreate = '/doc'
        public static readonly DocDelete = '/doc'
    }

    public static readonly Services = class {

        public static readonly Chat = 'chat'
        public static readonly DocChat = 'doc_chat'
        public static readonly DocDelete = 'doc'
    }

    public static readonly ChatMessages = class {
        public static readonly MaxTitleLength = 100
    }

    public static readonly WebsocketCommands = class {
        public static readonly Stream = 'stream'
    }

    public static readonly LoginErrors = class {
        public static readonly InvalidCredentials = 'login.errorMessage.invalidCredentials';
    }

    public static readonly FeaturePlugins = class {
        public static readonly Chat = 'chat'
        public static readonly DocumentChat = 'docchat'
        public static readonly Summaries = 'summaries'
    }

    public static readonly Strings = class {
        public static readonly Comma = ','
    }
}