import { FormEvent, Fragment, useContext, ReactNode, useEffect } from "react";
import {
    Button,
    Container,
    Avatar,
    Typography,
    Box,
    CssBaseline,
    Link,
    useTheme
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';

import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import UserStoreContext from "../../contexts/UserStoreProvider";
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface LoginForwardProps {
    pageTitle?: ReactNode;
}

const LoginForward: React.FC<LoginForwardProps> = ({ pageTitle }) => {

    const { t } = useTranslation();
    const { userStore } = useContext(UserStoreContext);

    const termsOfServiceUrl = process.env.REACT_APP_TERMS_OF_SERVICE_URL

    const loginHandler = async (event: FormEvent) => {
        event.preventDefault();

        if (userStore !== undefined) {
            let username = ''
            let password = ''
            console.log(userStore)
            userStore.login(username, password)
        }
    };

    const { instance } = useMsal();
    const theme = useTheme();

    const isBlue = process.env.REACT_APP_NAME === 'LBBW'
    console.log('isBlue', isBlue)

    useEffect(() => {
        if (instance) {

            const callbackId = instance.addEventCallback((event) => {
                switch (event.eventType) {
                    case EventType.LOGIN_SUCCESS:
                        console.log('LOGIN_SUCCESS');
                        userStore?.setLoggedIn(true);
                        break;
                    case EventType.HANDLE_REDIRECT_START:
                        console.log('HANDLE_REDIRECT_START');
                        break;
                    case EventType.HANDLE_REDIRECT_END:
                        console.log('HANDLE_REDIRECT_END');
                        //userStore?.setLoggedIn(true);
                        break;
                    case EventType.INITIALIZE_END:
                        console.log('INITIALIZE_END');
                        // instance.loginRedirect();

                        break;
                    case EventType.ACQUIRE_TOKEN_SUCCESS:
                        console.log('ACQUIRE_TOKEN_SUCCESS');
                        // instance.loginRedirect();

                        break;
                    case EventType.RESTORE_FROM_BFCACHE:
                        console.log('RESTORE_FROM_BFCACHE')
                        break;

                    case EventType.SSO_SILENT_START:
                        console.log('SSO_SILENT_START')
                        break;

                    case EventType.SSO_SILENT_SUCCESS:
                        console.log('SSO_SILENT_SUCCESS')
                        break;
                    //userStore?.setLoggedIn(true);
                    default:
                        console.log('default', event.eventType)
                        break;
                }
            });

            return () => {
                if (callbackId) {
                    instance.removeEventCallback(callbackId); // Cleanup event callback on unmount.
                }
            };
        }
    }, [instance, userStore]);

    return (
        <Fragment>
            <CssBaseline />
            <Container maxWidth="md" sx={containerStyle}>
                {/* Hero Section */}
                <Box sx={mainContentStyle}>
                    <Box sx={heroSectionStyle}>
                        <Typography variant="h2" gutterBottom color={'textPrimary'}>
                            {pageTitle || isBlue === false ? t('login.title') : t('login_blue.title')}
                        </Typography>
                        {isBlue === false &&
                            <Typography variant="h6" color="textSecondary" paragraph>
                                {t('login.subtitle1')}
                            </Typography>
                        }
                        {isBlue === true &&
                            <LazyLoadImage
                                height={'120px'}
                                src={'./Schriftzug_bluegpt_Positiv_RGB.png'}
                                alt={`App Logo`}
                                effect="blur"
                                style={{
                                    filter:
                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                    objectFit: 'contain',
                                    width: '100%',
                                    height: '100%'
                                }}
                            />
                        }
                    </Box>

                    {/* Login Section */}
                    <Box sx={loginSectionStyle}>
                        <Avatar sx={avatarStyle}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography variant="h5" gutterBottom>
                            {t('login.subtitle2')}
                        </Typography>
                        <Box component="form">
                            <Button onClick={loginHandler} fullWidth variant="contained" sx={loginButtonStyle}>
                                {t('login.signin')}
                            </Button>
                        </Box>
                    </Box>
                </Box>

                {/* Footer */}
                <Box sx={footerStyle}>
                    <Typography variant="body2" color="textSecondary" align="center">
                        {'© '}
                        {new Date().getFullYear()} GenAI
                        {'.'}
                        <Link color="inherit" href="#">
                            Privacy Policy
                        </Link>
                        {' | '}
                        <Link color="inherit" href={termsOfServiceUrl}>
                            Terms of Service
                        </Link>
                    </Typography>
                </Box>
            </Container>
        </Fragment>
    );
}

export default observer(LoginForward);

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh', // Ensures the container takes at least the full height of the viewport
} as const;

const mainContentStyle = {
    flex: 1, // Lets the main content grow and consume available space
    paddingBottom: '2rem' // Some padding before the footer
} as const;

const heroSectionStyle = {
    py: 8,
    textAlign: 'center',
    backgroundImage: 'url(path-to-background-image.jpg)',  // Optional: Add an appealing background image
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#ffffff',  // White color for better contrast with background
} as const;

const loginSectionStyle = {
    py: 4,
    mt: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: 8,
    boxShadow: 3,
    padding: 3
} as const;

const avatarStyle = {
    m: 1
} as const;

const loginButtonStyle = {
    my: 2
} as const;

const footerStyle = {
    mt: 5,
    py: 3,
    borderTop: '1px solid #eaeaea'
} as const;
