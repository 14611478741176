import { action, makeAutoObservable, observable } from "mobx";
import { conversationService } from "../services/ConversationService";
import { Conversation, Message } from "../types/types_api";
import { RootStore } from "./RootStore";

import { IUserStore } from "./interfaces/IUserStore";
import { convertMessageToChatMessageProps } from "../utils/chat/chatMessages";
import { ChatMessageProps } from "../types/ChatTypes";
import i18n from "../i18n";
import { IFeaturePluginStore } from "../plugin/types";
import axios from "axios";
import { errorHandler } from "../services/ErrorHandler";


type FeedbackCallback = (messageId: string, feedback: boolean) => void
type ConversationSelectedCallback = (messageId: string, messages: ChatMessageProps[]) => void
type ConversationDeletedCallback = (messageId: string) => void

export class ConversationStore {

    rootStore: RootStore | undefined = undefined

    _baseChatStore: IFeaturePluginStore | undefined = undefined

    @observable
    _currentConversations: Conversation[] = []

    _currentMessages: Message[] = []

    _currentConversationsMap: Map<string, Conversation> = new Map<string, Conversation>()

    _selectedConversation: Conversation | undefined = undefined

    _feedbackCallback: FeedbackCallback | undefined = undefined
    _conversationSelectedCallback: ConversationSelectedCallback | undefined = undefined
    _conversationDeletedCallback: ConversationDeletedCallback | undefined = undefined

    @observable
    _conversationsLoading: boolean = false

    constructor(rootStore: RootStore,
        baseChatStore: IFeaturePluginStore | undefined,
        userStore: IUserStore | undefined) {
        makeAutoObservable(this)
        this._currentConversations = []
        this._currentConversationsMap = new Map<string, Conversation>()
        this._selectedConversation = undefined
        this.rootStore = rootStore
        this._baseChatStore = baseChatStore
        this._conversationSelectedCallback = undefined
        this._conversationDeletedCallback = undefined
        this._conversationsLoading = true
    }

    get conversationsLoading() {
        return this._conversationsLoading
    }

    @action
    setConversationsLoading(conversationsLoading: boolean) {
        this._conversationsLoading = conversationsLoading
    }

    set conversationSelectedCallback(conversationHandler: ConversationSelectedCallback | undefined) {
        this._conversationSelectedCallback = conversationHandler
    }
    set conversationDeletedCallback(conversationHandler: ConversationDeletedCallback | undefined) {
        this._conversationDeletedCallback = conversationHandler
    }

    set feedbackCallback(feedbackCallback: FeedbackCallback | undefined) {
        this._feedbackCallback = feedbackCallback
    }

    get currentConversations(): Conversation[] {
        return this._currentConversations
    }

    @action
    setCurrentConversations(conversations: Conversation[]) {
        this._currentConversations = conversations
        this._currentConversationsMap = new Map<string, Conversation>()
        conversations.forEach((conversation) => {
            this._currentConversationsMap.set(conversation.id, conversation)
        })
    }

    get currentMessages(): Message[] {
        return this._currentMessages
    }

    @action
    setCurrentMessages(messages: Message[]) {
        this._currentMessages = messages
    }

    setSelectedConversation(conversation: Conversation | undefined) {
        this._selectedConversation = conversation
    }


    setSelectedConversationById(conversationId: string) {
        let conversation = this._currentConversationsMap.get(conversationId)
        if (conversation !== undefined) {
            this._selectedConversation = conversation
        }
    }

    async createConversation(userId: string, title: string, service: string, model: string, token?: string): Promise<boolean> {

        try {
            let result: Conversation | undefined = await conversationService.createConversation(userId, service, model, title, token)
            if (result !== undefined) {
                let conversations = [result]
                this._currentConversationsMap.set(result.id, result)
                this._currentConversations.forEach((conversation: Conversation) => {
                    conversations.push(conversation)
                })
                this.setCurrentConversations(conversations)

                this._selectedConversation = result
                return Promise.resolve(true)
            }
            return Promise.resolve(false)

        } catch (error) {
            if (axios.isAxiosError(error)) {
                errorHandler(error)
                this.rootStore?.notify(i18n.t("errorMessages.conversation.create"), { variant: 'error' })
                return Promise.resolve(false)
            } else {
                return Promise.resolve(false)
            }
        }
    }

    async retrieveConversationsForUser(userId: string, serviceName: string, token?: string) {

        try {
            let conversations: Conversation[] = await conversationService.getConversations(userId, token)
            if (conversations !== undefined) {
                conversations = conversations.filter((conversation: Conversation) => {
                    return conversation.service === serviceName
                })
                this.setConversationsLoading(false)
                this.setCurrentConversations(conversations)
            } else {
                this.setCurrentConversations([])
            }
            // console.log("Conversations: ", conversations)
        } catch (error) {

            this.rootStore?.notify(i18n.t("errorMessages.conversation.retrieve"), { variant: 'error' })
            this.setCurrentConversations([])
            if (axios.isAxiosError(error)) {
                errorHandler(error)
                return Promise.resolve(undefined)
            } else {
                return Promise.resolve(undefined)
            }
        }
    }

    async retrieveConversationMessages(userId: string, token?: string) {

        try {
            let messages: Message[] = await conversationService.getConverstationMessages(userId, token)
            this.setCurrentMessages(messages)

        } catch (error) {

            if (axios.isAxiosError(error)) {
                errorHandler(error)
                this.rootStore?.notify(i18n.t("errorMessages.conversation.retrieveMessages"), { variant: 'error' })
            } else {
                console.log(error)
            }
        }
    }

    async deleteConversation(conversationId: string, token?: string) {

        try {
            let result = await conversationService.deleteConversation(conversationId, token)
            this._currentConversationsMap.delete(conversationId)
            let conversations: Conversation[] = []
            this._currentConversationsMap.forEach((conversation: Conversation) => {
                if (conversation.id !== conversationId) {
                    conversations.push(conversation)
                } else {
                    console.log("deleted conversation: ", conversation)
                }
            })
            // console.log("Conversations: ", conversations)
            this.setCurrentConversations(conversations)
            if (this._selectedConversation !== undefined &&
                this._selectedConversation.id === conversationId) {
                this.setCurrentMessages([])
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                errorHandler(error)
                this.rootStore?.notify(i18n.t("errorMessages.conversation.delete"), { variant: 'error' })
            } else {
                console.log(error)
            }
        }
    }

    async setFeedback(messageId: string, feedback: boolean, token?: string) {

        try {
            let result = await conversationService.setFeedback(messageId, feedback, token)
            // console.log("result: ", result)
        } catch (error) {
            if (axios.isAxiosError(error)) {
                errorHandler(error)
                this.rootStore?.notify(i18n.t("errorMessages.conversation.messageFeedback"), { variant: 'error' })
            } else {
                console.log(error)
            }
        }
    }

    async onConversationSelected(conversationId: string) {

        if (this._baseChatStore !== undefined) {


            let token = await this.rootStore?.userStore?.getAccessToken()
            // console.log("this.userStore: ", this.rootStore?.userStore)
            if (token !== undefined) {

                // NOTE: 
                // errors are capture in retrieveConversationMessages
                //
                await this.retrieveConversationMessages(conversationId, token)
                // console.log("retrievedMessages: ")
                this.setSelectedConversationById(conversationId)

                // convert conversation messages to chat messages
                let currentMessages: Message[] | undefined = this.currentMessages
                // console.log(currentMessages)
                if (currentMessages !== undefined) {
                    //this._pluginStore._chatStore?.setCurrentMessages(currentMessage)
                    const chatMessagePropsArray: ChatMessageProps[] = currentMessages.map(convertMessageToChatMessageProps);

                    if (this._conversationSelectedCallback !== undefined) {
                        this._conversationSelectedCallback(conversationId, chatMessagePropsArray)
                    }
                }
            }
        }
    }

    async onConversationDeleted(conversationId: string) {

        if (this._baseChatStore !== undefined) {
            // console.log("onConversationDeleted: ", conversationId)
            let token = await this.rootStore?._userStore?.getAccessToken()
            if (token !== undefined) {

                this.deleteConversation(conversationId, token)

                if (this._conversationDeletedCallback !== undefined) {
                    this._conversationDeletedCallback(conversationId)
                }
            }
        }
    }

    async onFeedbackThumbUp(messageId: string) {

        try {
            let token = await this.rootStore?.userStore?.getAccessToken()
            if (this._feedbackCallback !== undefined) {
                this._feedbackCallback(messageId, true)
            }
            let result = await conversationService.setFeedback(messageId, true, token)
            this.rootStore?.notify(i18n.t("conversation.feedback.success"), { variant: 'success' })

            // update ui message
        } catch (error) {
            if (axios.isAxiosError(error)) {
                errorHandler(error)
                this.rootStore?.notify(i18n.t("errorMessages.conversation.messageFeedback"), { variant: 'error' })
            } else {
                console.log(error)
            }
        }
    }

    async onFeedbackThumbDown(messageId: string) {

        try {
            if (this._feedbackCallback !== undefined) {
                this._feedbackCallback(messageId, false)
            }
            let token = await this.rootStore?.userStore?.getAccessToken()
            let result = await conversationService.setFeedback(messageId, false, token)
            this.rootStore?.notify(i18n.t("conversation.feedback.success"), { variant: 'success' })

        } catch (error) {
            if (axios.isAxiosError(error)) {
                errorHandler(error)
                this.rootStore?.notify(i18n.t("errorMessages.conversation.messageFeedback"), { variant: 'error' })
            } else {
                console.log(error)
            }
        }
    }

    async updateConversationTitle(conversationId: string, title: string) {
        try {
            let token = await this.rootStore?.userStore?.getAccessToken()
            console.log('updateConversationTitle')
            let result = await conversationService.updateConversationTitle(conversationId, title, token)

        } catch (error) {
            if (axios.isAxiosError(error)) {
                errorHandler(error)
                this.rootStore?.notify(i18n.t("errorMessages.conversation.messageFeedback"), { variant: 'error' })
            } else {
                console.log(error)
            }
        }
    }

    clear() {
        console.log('ConversationStore.clear')
        this._currentConversations = []
        this._currentConversationsMap = new Map<string, Conversation>()
        this._selectedConversation = undefined
    }
}