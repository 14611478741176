import { HandleMesssageResult } from "../stores/StreamingChatStore"


export enum ContentStatus {
    Okay = "okay",
    Inappropriate = "inappropriate",
    Error = "error"
}

export interface IContentFilter {
    init(): Promise<void>
    checkInput(text: string): Promise<IContentFilterResult>
    clear(): void
    containsCriticalCategories(result: HandleMesssageResult): boolean
}

export interface IContentFilterResult {
    allowed: boolean
    categories: string[]
    entities: string[]
}
