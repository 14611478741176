import axios, { AxiosInstance } from 'axios';
import { PromptSuggestion } from '../components/ChatBox/PromptSuggestions/PromptSuggestions';

import prompts_chat from '../prompts/chat.json';
import prompts_docchat from '../prompts/doc_chat.json';

class PromptSuggestionService {

    private client: AxiosInstance;

    constructor(baseURL: string, service: string = 'info') {

        this.client = axios.create({ baseURL: baseURL + `/api/${service}` });
    }

    async getPromptSuggestions(token: string, serviceName: string, language: string = "de"): Promise<PromptSuggestion[]> {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            },
            withCredentials: true
        };

        let promptSuggestions: PromptSuggestion[] = [];

        if (serviceName === "chat") {
            if (language === "de") {
                promptSuggestions = prompts_chat.promptSuggestions_DE

            } else if (language === "en") {
                promptSuggestions = promptSuggestions = prompts_chat.promptSuggestions_EN
            }
        } else if (serviceName === "doc_chat") {
            if (language === "de") {
                promptSuggestions = prompts_docchat.promptSuggestions_DE

            } else if (language === "en") {
                promptSuggestions = prompts_docchat.promptSuggestions_EN
            }
        }

        return Promise.resolve(promptSuggestions);
    }
}

export const promptSuggestionService = new PromptSuggestionService(
    process.env.REACT_APP_BACKEND_URL ?? "http://localhost:3001",
    "info"
);
