import React, { useEffect } from 'react';
import { Box, Typography, SelectChangeEvent, InputLabel, FormControl, Select, MenuItem, Paper } from '@mui/material';
import { SelectableModel } from '../../types/types_api';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';


export interface ModelSelectionBannerProps {
    modelSelectionVisible: boolean;
    modelSelectionTitle: string
    labelSelectModel: string;
    availableModels: SelectableModel[];
    handleModelSelection?: (selectedModelId: SelectableModel) => void;
}

const ModelSelectionBanner: React.FC<ModelSelectionBannerProps> = ({
    modelSelectionVisible,
    modelSelectionTitle,
    labelSelectModel,
    availableModels,
    handleModelSelection,
}) => {

    const [selectedModel, setSelectedModel] = React.useState(availableModels.length > 0 ? availableModels[0].name : '');
    const handleChange = (event: SelectChangeEvent) => {

        setSelectedModel(event.target.value);

        if (handleModelSelection) {
            for (let i = 0; i < availableModels.length; i++) {
                if (availableModels[i].name === event.target.value) {
                    let mm = availableModels[i] as SelectableModel;
                    console.log("selectedModel", mm.name)
                    handleModelSelection(availableModels[i]);
                    break;
                }
            }
        }
    };

    useEffect(() => {
        if (availableModels.length > 0) {
            setSelectedModel(availableModels[0].name);
        }
    }, [availableModels]);

    return (
        <Box position="relative">
            <Box
                position="absolute"
                width="20%"
                left="35%" // to center the alert
                style={{ display: modelSelectionVisible ? 'block' : 'none' }}
            >
                <Paper sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', pl: 2, pr: 2 }} elevation={3} >
                    <Box display={'flex'} flexDirection={'column'} flexGrow={1} >
                        {/* <Typography textAlign={'center'} variant={'h6'}>{modelSelectionTitle}</Typography> */}
                        <FormControl sx={{ m: 1 }} size="small" fullWidth>
                            <InputLabel id="demo-select-small-label">{labelSelectModel}</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={selectedModel}
                                label={labelSelectModel}
                                onChange={handleChange}
                            >
                                {availableModels.map((model: SelectableModel) => {
                                    return (<MenuItem key={model.guid} value={model.name}>{model.name}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Paper>
            </Box>
        </Box >
    );
};

export default observer(ModelSelectionBanner);
