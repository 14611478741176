import { FormEvent, Fragment, useRef, useContext } from "react";
import {
    Button,
    Container,
    Avatar,
    TextField,
    Typography, Box, Alert
} from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import RootStoreContext from "../../contexts/RootStoreProvider";
import { observer } from "mobx-react";
import { Constants } from "../../contants/Constants";

import { useTranslation } from "react-i18next";
import { InputAdornment } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';


const Login: React.FC = ({ }) => {
    const navigate = useNavigate()
    const { rootStore } = useContext(RootStoreContext)
    const { t } = useTranslation();

    const usernameRef = useRef<HTMLInputElement>(null!)
    const passwordRef = useRef<HTMLInputElement>(null!)
    const termsOfServiceUrl = process.env.REACT_APP_TERMS_OF_SERVICE_URL

    const submitHandler = async (event: FormEvent) => {
        event.preventDefault()

        let userName = usernameRef.current.value
        let password = passwordRef.current.value

        try {
            if (rootStore !== undefined) {
                if (rootStore.userStore !== undefined) {

                    let success = await rootStore.userStore.login(userName, password)
                    if (success) {
                        navigate(Constants.Routes.Chat)
                    } else {

                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Fragment>
            <Container maxWidth="md" sx={containerStyle}>
                {/* Hero Section */}
                <Box sx={heroSectionStyle}>
                    <Typography variant="h2" gutterBottom color={'textPrimary'}>
                        {t('login.title')}
                    </Typography>

                    <Typography variant="h6" color="textSecondary" paragraph>
                        {t('login.subtitle1')}
                    </Typography>

                </Box>

                {/* Login Section */}
                <Box sx={loginSectionStyle}>
                    <Avatar sx={avatarStyle}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography variant="h5" gutterBottom>
                        {t('login.subtitle2')}
                    </Typography>
                    <Box component="form" onSubmit={submitHandler}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label={t('login.username')}
                            name="username"
                            autoFocus
                            inputRef={usernameRef}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            type="password"
                            label={t('login.password')}
                            name="password"
                            inputRef={passwordRef}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <VpnKeyIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button type="submit" fullWidth variant="contained" sx={loginButtonStyle}>
                            {t('login.signin')}
                        </Button>
                    </Box>

                    {rootStore?.userStore?.errorMessage &&
                        <Alert severity="error" sx={{ mt: 2 }}>{t(rootStore.userStore.errorMessage)}</Alert>}
                </Box>
            </Container>
        </Fragment>
    )
}

export default observer(Login)



const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh', // Ensures the container takes at least the full height of the viewport
} as const;

const heroSectionStyle = {
    py: 8,
    textAlign: 'center',
    backgroundImage: 'url(path-to-background-image.jpg)',  // Optional: Add an appealing background image
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#ffffff',  // White color for better contrast with background
} as const;

const loginSectionStyle = {
    py: 4,
    mt: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: 8,
    boxShadow: 3,
    padding: 3
} as const;

const avatarStyle = {
    m: 1
} as const;

const loginButtonStyle = {
    my: 2
} as const;
