import { OptionsWithExtraProps, VariantType } from "notistack";

import { UIStore } from "./UIStore";

import { IUserStore } from "./interfaces/IUserStore";
import { IFeaturePlugin } from "../plugin/types";
import { IEventConnection } from "../utils/serverside/ConnectionInterfaces";

import { IServerConnectionHandler, SSEConnectionHandler } from "../utils/serverside/ServerConnectionHandler";
import { action, makeAutoObservable, observable } from "mobx";
import { IContentFilter } from "../AIShield/AIShieldTypes";
import { PassThroughContentFilter } from "../AIShield/PassThroughShield";
import { AIShield } from "../AIShield/AIShield";


export class RootStore {

    // chatStore: ChatStore;
    // documentChatStore: DocumentChatStore;
    uiStore: UIStore;
    _userStore: IUserStore | undefined = undefined;

    _eventConnection: IEventConnection | undefined = undefined

    _serverConnectionHandler: IServerConnectionHandler | undefined = undefined

    baseUrl: string | undefined

    @observable
    _plugins: Array<IFeaturePlugin> = []

    @observable
    _activePlugin: IFeaturePlugin | undefined = undefined

    _notificationHandler: ((message: string, variant: OptionsWithExtraProps<VariantType>) => void) | undefined = undefined

    _contentFilter: IContentFilter = new PassThroughContentFilter()

    _pluginsInitialized: boolean = false

    @observable
    _termsofUseAccepted: boolean = false

    @observable
    _appReady: boolean = false

    constructor() {
        makeAutoObservable(this)
        // this.chatStore = new ChatStore(this)
        // this.documentChatStore = new DocumentChatStore(this)

        this.uiStore = new UIStore(this)
        //this._userStore = new UserStoreMSAL(this)
        this._eventConnection = undefined

        this._serverConnectionHandler = new SSEConnectionHandler()
        this._termsofUseAccepted = false

        // if (isProduction === false) {
        //     try {
        //         let user_id = uuidv4()
        //         this.connectStreaming(user_id)
        //     } catch (error) {
        //         throw error
        //     }
        // }

        this._contentFilter = new PassThroughContentFilter()
    }

    get pluginsInitialized(): boolean {
        return this._pluginsInitialized
    }

    get termsofUseAccepted(): boolean {
        return this._termsofUseAccepted
    }

    @action
    setTermsOfUseAccepted(accepted: boolean) {
        this._termsofUseAccepted = accepted
        console.log("RootStore.setTermsOfUseAccepted", accepted)
    }

    get appReady(): boolean {
        return this._appReady
    }

    @action
    setAppReady(appReady: boolean) {
        this._appReady = appReady
    }

    async initAIShield() {
        if (this._contentFilter) {
            await this._contentFilter.init()
            console.log("RootStore.initAIShield: before app ready")
            this.setAppReady(true)
        }
    }

    @action
    setActivePlugin(plugin: IFeaturePlugin) {
        this._activePlugin = plugin
    }

    get plugins(): Array<IFeaturePlugin> {
        return this._plugins
    }

    get activePlugin(): IFeaturePlugin | undefined {
        return this._activePlugin
    }

    get userStore(): IUserStore | undefined {
        return this._userStore
    }

    set userStore(userStore: IUserStore | undefined) {
        this._userStore = userStore
    }

    @action
    setPlugins(plugins: Array<IFeaturePlugin>) {
        this._plugins = plugins
    }

    set notificationHandler(notificationHandler: ((message: string, variant: OptionsWithExtraProps<VariantType>) => void) | undefined) {
        this._notificationHandler = notificationHandler
    }

    initializePlugins(plugins: Array<IFeaturePlugin>) {

        if (this._pluginsInitialized === false) {

            this.setActivePlugin(plugins[0])
            this._pluginsInitialized = true

            plugins.forEach((plugin: IFeaturePlugin) => {
                plugin.initialize(this)
            })

            console.log("_serverConnectionHandler", this._serverConnectionHandler)
            if (this._serverConnectionHandler) {
                this._serverConnectionHandler.plugins = this._plugins
            }
        }
    }

    addFeaturePlugin(featurePlugin: IFeaturePlugin) {

        // check if plugin is already added
        let plugin = this._plugins.find((plugin) => {
            return plugin.name === featurePlugin.name
        })
        if (plugin === undefined) {
            this._plugins.push(featurePlugin)
        }

        if (this._serverConnectionHandler) {
            this._serverConnectionHandler.plugins = this._plugins
        }
    }

    notify(message: string, variant: OptionsWithExtraProps<VariantType>) {
        if (this._notificationHandler) {
            this._notificationHandler(message, variant)
        }
    }

    // connectStreaming(userId: string, accessToken: string = '') {
    //     let websocketBackendUrl = process.env.REACT_APP_WEBSOCKET_URL
    //     if (websocketBackendUrl !== undefined) {
    //         try {
    //             if (this._eventConnection == undefined) {
    //                 let url = `${websocketBackendUrl}/api/streaming/${userId}?token=${accessToken}`
    //                 this._eventConnection = connectionFactory.createConnection('ws', url)
    //             }
    //             if (this._eventConnection !== null) {
    //                 this._eventConnection.connect()
    //                 this._eventConnection.onMessage = this.onMessage.bind(this)
    //                 //this._websocketClient.onCompleted = this.onCompleted.bind(this)
    //             }
    //         } catch (error) {
    //             throw error
    //         }
    //     }
    // }

    fireUpdateUI() {
        this.uiStore.setUpdateUI()
    }


    clear() {

        console.log('RootStore.clear')
        this._activePlugin = undefined

        this._notificationHandler = undefined
        this._pluginsInitialized = false

        this._plugins.forEach((plugin) => {
            if (plugin.featureStore) {
                plugin.featureStore.clear()
            }
        })

        this._plugins = []
        this._termsofUseAccepted = false
        this.uiStore.clear()
        this.userStore?.clear()
    }
}


