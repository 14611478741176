
import { HandleMesssageResult } from "../stores/StreamingChatStore";
import { IContentFilter, IContentFilterResult } from "./AIShieldTypes";

export class PassThroughContentFilter implements IContentFilter {

    async init() {
        console.log("PassThroughContentFilter initialized")
    }

    async checkInput(text: string): Promise<IContentFilterResult> {
        let contentFilterResult: IContentFilterResult = {
            allowed: true,
            categories: [],
            entities: []
        }
        return contentFilterResult;
    }

    containsCriticalCategories(result: HandleMesssageResult): boolean {
        return false
    }

    clear() {

    }
}