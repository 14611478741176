import './App.css';

import './i18n';

import { useContext, useEffect } from 'react';
import { GlobalDebug } from './utils/GlobalDebug';

import PagesRoutes from './Pages';
import { UserStoreProvider } from './contexts/UserStoreProvider';
import { IUserStore } from './stores/interfaces/IUserStore';
import RootStoreContext from './contexts/RootStoreProvider';
import { useNavigate } from 'react-router-dom';
import { UserMenuOptions } from './types/UITypes';
import { getFeaturePlugins } from './plugin/featureFactory';
import { observer } from 'mobx-react-lite';


export interface AppProps {
  userStore?: IUserStore
  authenticationGuard?: React.ReactNode
  loginComponent?: React.ReactNode
  logoutComponent?: React.ReactNode
}

const App: React.FC<AppProps> = ({ userStore, authenticationGuard, loginComponent }) => {

  const { rootStore } = useContext(RootStoreContext)

  const navigate = useNavigate()

  let userMenuOptions: UserMenuOptions[] = [
    {
      title: 'Logout',
      icon: null,
      action: () => {
        // console.log("Logout button clicked")
        if (userStore) {
          userStore.logout()
          navigate('/login')
        }
      }
    }
  ];

  // useEffect(() => {
  //   (process.env.NODE_ENV === "production" ||
  //     process.env.REACT_APP_ENV === "STAGING") &&
  //     GlobalDebug(false, true);

  //   if (userStore !== undefined) {
  //     if (rootStore !== undefined) {
  //       rootStore.userStore = userStore
  //     }

  //     userStore.rootStore = rootStore

  //     let checkUserLogIn = async () => {
  //       let userLogin = await userStore.checkUserLogIn()
  //       if (userLogin) {
  //         if (rootStore !== undefined) {

  //           let featurePlugins = getFeaturePlugins(rootStore)
  //           if (featurePlugins.length > 0) {

  //             console.log('initializePlugins')
  //             rootStore.initializePlugins(featurePlugins)
  //             rootStore.setPlugins(featurePlugins)
  //           }
  //         }
  //         const initAIShield = async () => {
  //           if (rootStore !== undefined) {
  //             await rootStore.initAIShield()
  //           }
  //         }
  //         initAIShield()
  //         navigate('/chat')
  //       } else {
  //         // console.log("User not logged in")
  //         //navigate('/login')
  //       }
  //     }

  //     checkUserLogIn()
  //   }

  //   // console.log("UserStore initialized")

  // }, [userStore?.isLoggedIn]);

  return <>
    <UserStoreProvider userStore={userStore}>
      <div className="App">

        {rootStore !== undefined && <PagesRoutes
          authenticationGuard={authenticationGuard}
          loginComponent={loginComponent}
          userMenuOptions={userMenuOptions} />
        }
      </div >
    </UserStoreProvider>
  </>
}

export default observer(App);
